import { FILTER_TYPES, NextSDRDateTypes } from "@bds/next-sdr";
import { ColonnaBds } from "@bds/common-tools";
import { Esecutivita, Registro } from "src/app/entities/TipoDocumento";
import { CodiceName, CodiceProfiloCommittente } from "src/app/entities/PubblicazioneCommittente";
import { Utils } from "src/app/utilities/utils";
import { EsitoIter } from "src/app/entities/RegistroAccessi";



export const cols: ColonnaBds[] = [
  {
    field: "oggetto",
    header: "Oggetto",
    filterField: "oggetto",
    sortField: "oggetto",
    style: {},
    headerClass: ["header-column", "oggetto-column"],
    filterClass: ["filter-column", "oggetto-column"],
    bodyClass: ["oggetto-column"],
    fieldType: "string",
    filterMatchMode: FILTER_TYPES.string.containsIgnoreCase,
    useFilterMatchMode: true,
    default: true
  },
  {
    field: "datiPubblicazione",
    header: "Dati Pubblicazione",
    filterField: "numeroPubblicazione",
    sortField: ["numeroPubblicazione","annoPubblicazione"],
    style: {},
    headerClass: ["header-column", "dati-pubblicazione-column"],
    filterClass: ["filter-column", "dati-pubblicazione-column"],
    bodyClass: ["dati-pubblicazione-column"],
    fieldType: "number",
    filterMatchMode: FILTER_TYPES.not_string.equals,
    useFilterMatchMode: true,
    default: true
  },
  {
    field: "articolazione",
    header: "Articolazione",
    filterField: "articolazione",
    sortField: "articolazione",
    style: {},
    headerClass: ["header-column", "articolazione-column"],
    filterClass: ["filter-column", "articolazione-column"],
    bodyClass: ["articolazione-column"],
    fieldType: "string",
    filterMatchMode: FILTER_TYPES.string.containsIgnoreCase,
    useFilterMatchMode: true,
    default: true
  },
  {
    field: "numeroPubblicazione",
    header: "Numero pubblicazione",
    filterField: "numeroPubblicazione",
    // sortField: ["annoRegistrazione", "numeroRegistrazione"],
    style: {},
    headerClass: ["header-column", "numero-pubblicazione-column"],
    filterClass: ["filter-column", "numero-pubblicazione-column"],
    bodyClass: ["numero-pubblicazione-column"],
    fieldType: "number",
    filterMatchMode: FILTER_TYPES.not_string.equals,
    useFilterMatchMode: true,
    default: true
  },
  {
    field: "annoPubblicazione",
    header: "Anno pubblicazione",
    filterField: "annoPubblicazione",
    // sortField: ["annoProposta", "numeroProposta"],
    sortField:"annoPubblicazione",
    style: {},
    headerClass: ["header-column", "anno-pubblicazione-column"],
    filterClass: ["filter-column", "anno-pubblicazione-column"],
    bodyClass: ["anno-pubblicazione-column"],
    fieldType: "number",
    filterMatchMode: FILTER_TYPES.not_string.equals,
    useFilterMatchMode: true,
    default: true
  },
  {
    field: "dataInserimento",
    header: "Data Inserimento",
    filterField: "dataInserimento",
    sortField: "dataInserimento",
    style: {},
    headerClass: ["header-column", "data-inserimento-column"],
    filterClass: ["filter-column", "data-inserimento-column"],
    bodyClass: ["data-inserimento-column"],
    fieldType: NextSDRDateTypes.ZonedDateTime,
    filterMatchMode: FILTER_TYPES.not_string.equals,
    useFilterMatchMode: true,
    default: true
  },
  {
    field: "dataEsecutivita",
    header: "Data Esecutività",
    filterField: "tipiDocumentiList.dataEsecutivita",
    sortField: "tipiDocumentiList.dataEsecutivita",
    style: {},
    headerClass: ["header-column", "data-esecutivita-column"],
    filterClass: ["filter-column", "data-esecutivita-column"],
    bodyClass: ["data-esecutivita-column"],
    fieldType: NextSDRDateTypes.ZonedDateTime,
    filterMatchMode: FILTER_TYPES.not_string.equals,
    useFilterMatchMode: true,
    default: true
  },
  {
    field: "datiRegistro",
    header: "Dati Registro",
    filterField: "tipiDocumentiList.numeroRegistro",
    sortField: "tipiDocumentiList.numeroRegistro",
    style: {},
    headerClass: ["header-column", "dati-registro-column"],
    filterClass: ["filter-column", "dati-registro-column"],
    bodyClass: ["dati-registro-column"],
    fieldType: "number",
    filterMatchMode: FILTER_TYPES.not_string.equals,
    useFilterMatchMode: true,
    default: true
  },
  {
    field: "dataAdozione",
    header: "Data Adozione",
    filterField: "tipiDocumentiList.dataAdozione",
    sortField: "tipiDocumentiList.dataAdozione",
    style: {},
    headerClass: ["header-column", "data-adozione-column"],
    filterClass: ["filter-column", "data-adozione-column"],
    bodyClass: ["data-adozione-column"],
    fieldType: NextSDRDateTypes.ZonedDateTime,
    filterMatchMode: FILTER_TYPES.not_string.equals,
    useFilterMatchMode: true,
    default: false
  },
  {
    field: "tipoProvvedimento",
    header: "Tipo Provvedimento",
    filterField: "provvedimento.tipoProvvedimento",
    sortField: "provvedimento.tipoProvvedimento",
    style: {},
    headerClass: ["header-column", "tipo-provvedimento-column"],
    filterClass: ["filter-column", "tipo-provvedimento-column"],
    bodyClass: ["tipo-provvedimento-column"],
    fieldType: "string",
    filterMatchMode: FILTER_TYPES.string.equals,
    useFilterMatchMode: true,
    default: true
  },
  {
    field: "dataUltimaModifica",
    header: "Data Ultima Modifica",
    filterField: "dataUltimaModifica",
    sortField: "dataUltimaModifica",
    style: {},
    headerClass: ["header-column", "data-ultima-modifica-column"],
    filterClass: ["filter-column", "data-ultima-modifica-column"],
    bodyClass: ["data-ultima-modifica-column"],
    fieldType: NextSDRDateTypes.ZonedDateTime,
    filterMatchMode: FILTER_TYPES.not_string.equals,
    useFilterMatchMode: true,
    default: true
  },
  {
    field: "registro",
    header: "Registro",
    filterField: "tipiDocumentiList.codiceRegistro",
    sortField: "tipiDocumentiList.registro", 
    style: {},
    headerClass: ["header-column", "registro-column"],
    filterClass: ["filter-column", "registro-column"],
    bodyClass: ["registro-column"],
    fieldType: "object",
    filterMatchMode: FILTER_TYPES.not_string.equals,
    useFilterMatchMode: true,
    default: true
  },
  {
    field: "esecutivita",
    header: "Esecutività",
    filterField: "tipiDocumentiList.esecutivita",
    sortField: "tipiDocumentiList.esecutivita",
    style: {},
    headerClass: ["header-column", "esecutivita-column"],
    filterClass: ["filter-column", "esecutivita-column"],
    bodyClass: ["esecutivita-column"],
    fieldType: "string",
    filterMatchMode: FILTER_TYPES.not_string.equals,
    useFilterMatchMode: true,
    default: true
  },
  {
    field: "numeroRegistro",
    header: "Numero registro",
    filterField: "tipiDocumentiList.numeroRegistro",
    sortField:"tipiDocumentiList.numeroRegistro",
    style: {},
    headerClass: ["header-column", "numero-registro-column"],
    filterClass: ["filter-column", "numero-registro-column"],
    bodyClass: ["numero-registro-column"],
    fieldType: "number",
    filterMatchMode: FILTER_TYPES.not_string.equals,
    useFilterMatchMode: true,
    default: true
  },
  {
    field: "annoRegistro",
    header: "Anno registro",
    filterField: "tipiDocumentiList.annoRegistro",
    sortField: "tipiDocumentiList.annoRegistro",
    style: {},
    headerClass: ["header-column", "anno-registro-column"],
    filterClass: ["filter-column", "anno-registro-column"],
    bodyClass: ["anno-registro-column"],
    fieldType: "number",
    filterMatchMode: FILTER_TYPES.not_string.equals,
    useFilterMatchMode: true,
    default: true
  },
  {
    field: "dataRegistro",
    header: "Data registrazione",
    filterField: "tipiDocumentiList.dataRegistro",
    sortField: "tipiDocumentiList.dataRegistro",
    style: {},
    headerClass: ["header-column", "data-registrazione-column"],
    filterClass: ["filter-column", "data-registrazione-column"],
    bodyClass: ["data-registrazione-column"],
    fieldType: NextSDRDateTypes.ZonedDateTime,
    filterMatchMode: FILTER_TYPES.not_string.equals,
    useFilterMatchMode: true,
    default: true
  },
  {
    field: "dataInizioPubblicazione",
    header: "Data pubblicazione",
    filterField: "periodoPubblicazioneList.dataDal",
    sortField: "periodoPubblicazioneList.dataDal",
    style: {},
    headerClass: ["header-column", "data-pubblicazione-column"],
    filterClass: ["filter-column", "data-pubblicazione-column"],
    bodyClass: ["data-pubblicazione-column"],
    fieldType: NextSDRDateTypes.ZonedDateTime,
    filterMatchMode: FILTER_TYPES.not_string.equals,
    useFilterMatchMode: true,
    default: true
  },
  {
    field: "dataPubblicazioneProfCom",
    header: "Data pubblicazione",
    filterField: "dataInserimento",
    sortField: "dataInserimento",
    style: {},
    headerClass: ["header-column", "data-inserimento-column"],
    filterClass: ["filter-column", "data-inserimento-column"],
    bodyClass: ["data-inserimento-column"],
    fieldType: NextSDRDateTypes.ZonedDateTime,
    filterMatchMode: FILTER_TYPES.not_string.equals,
    useFilterMatchMode: true,
    default: true
  },
  {
    field: "dataFinePubblicazione",
    header: "Data fine pubblicazione",
    filterField: "periodoPubblicazioneList.dataAl",
    sortField: "periodoPubblicazioneList.dataAl",
    style: {},
    headerClass: ["header-column", "data-fine-pubblicazione-column"],
    filterClass: ["filter-column", "data-fine-pubblicazione-column"],
    bodyClass: ["data-fine-pubblicazione-column"],
    fieldType: NextSDRDateTypes.ZonedDateTime,
    filterMatchMode: FILTER_TYPES.not_string.equals,
    useFilterMatchMode: true,
    default: true
  },
  {
    field: "tipoProcedimento",
    header: "Tipo Procedimento",
    filterField: "registroAccessi.tipoProcedimento",
    sortField: "registroAccessi.tipoProcedimento",
    style: {},
    headerClass: ["header-column", "tipoProcedimento-column"],
    filterClass: ["filter-column", "tipoProcedimento-column"],
    bodyClass: ["tipoProcedimento-column"],
    fieldType: "string",
    filterMatchMode: FILTER_TYPES.string.containsIgnoreCase,
    useFilterMatchMode: true,
    default: true
  },
  // is the same with articolazione just Header Changes for Registro Accessi
  {
    field: "articolazione2",
    header: "UO Procedente",
    filterField: "articolazione",
    sortField: "articolazione",
    style: {},
    headerClass: ["header-column", "articolazione2-column"],
    filterClass: ["filter-column", "articolazione2-column"],
    bodyClass: ["articolazione2-column"],
    fieldType: "string",
    filterMatchMode: FILTER_TYPES.string.containsIgnoreCase,
    useFilterMatchMode: true,
    default: true
  },
  {
    field: "attoDiIniziativa",
    header: "Atto di iniziativa",
    filterField: "attoDiIniziativa",
    // todo fix sort
    // sortField: "datiRegistrazio",
    style: {},
    headerClass: ["header-column", "attoDiIniziativa-column"],
    filterClass: ["filter-column", "attoDiIniziativa-column"],
    bodyClass: ["attoDiIniziativa-column"],
    fieldType: "number",
    filterMatchMode: FILTER_TYPES.not_string.equals,
    useFilterMatchMode: true,
    default: true
  },
  {
    field: "dataIniziativa",
    header: "Data Iniziativa",
    filterField: "dataIniziativa",
    // todo fix sort
    //sortField: "",
    style: {},
    headerClass: ["header-column", "dataIniziativa-column"],
    filterClass: ["filter-column", "dataIniziativa-column"],
    bodyClass: ["dataIniziativa-column"],
    fieldType: NextSDRDateTypes.ZonedDateTime,
    filterMatchMode: FILTER_TYPES.not_string.equals,
    useFilterMatchMode: true,
    default: true
  },
  {
    field: "controinteressati",
    header: "Controinteressati",
    filterField: "registroAccessi.controinteressati",
    // sortField: "registroAccessi.controinteressati",
    style: {},
    headerClass: ["header-column", "controinteressati-column"],
    filterClass: ["filter-column", "controinteressati-column"],
    bodyClass: ["controinteressati-column"],
    fieldType: "boolean", //------------------------------------------------------------------------------------------
    filterMatchMode: FILTER_TYPES.not_string.equals,
    useFilterMatchMode: true,
    default: true
  },
  {
    field: "esito",
    header: "Esito",
    filterField: "registroAccessi.esito",
    sortField: "registroAccessi.esito",
    style: {},
    headerClass: ["header-column", "esito-column"],
    filterClass: ["filter-column", "esito-column"],
    bodyClass: ["esito-column"],
    fieldType: "string",
    filterMatchMode: FILTER_TYPES.not_string.equals,
    useFilterMatchMode: true,
    default: true
  },
  {
    field: "provvedimentoFinale",
    header: "Provvedimento Finale",
    filterField: "provvedimentoFinale",
    //todo fix this sort
    // sortField: "datiRegistrazio",
    style: {},
    headerClass: ["header-column", "provvedimentoFinale-column"],
    filterClass: ["filter-column", "provvedimentoFinale-column"],
    bodyClass: ["provvedimentoFinale-column"],
    fieldType: "number",
    filterMatchMode: FILTER_TYPES.not_string.equals,
    useFilterMatchMode: true,
    default: true
  },
  {
    field: "dataChiusura",
    header: "Data Chiusura",
    filterField: "dataChiusura",
    //todo fix this sort
    //sortField: "",
    style: {},
    headerClass: ["header-column", "dataChiusura-column"],
    filterClass: ["filter-column", "dataChiusura-column"],
    bodyClass: ["dataChiusura-column"],
    fieldType: NextSDRDateTypes.ZonedDateTime,
    filterMatchMode: FILTER_TYPES.not_string.equals,
    useFilterMatchMode: true,
    default: true
  },
  {
    field: "motivazione",
    header: "Motivazione",
    // todo: need a tscol
    filterField: "registroAccessi.motivazione",
    sortField: "registroAccessi.motivazione",
    style: {},
    headerClass: ["header-column", "motivazione-column"],
    filterClass: ["filter-column", "motivazione-column"],
    bodyClass: ["motivazione-column"],
    fieldType: "string",
    filterMatchMode: FILTER_TYPES.string.containsIgnoreCase,
    useFilterMatchMode: true,
    default: true
  },

];

export const albiMainFieldsCols: string[] = [
  "datiPubblicazione",
  "datiRegistro",
  "oggetto",
  "esecutivita",
  "dataEsecutivita",
  "dataAdozione",
  "articolazione",
  "dataInizioPubblicazione",
  "dataFinePubblicazione"
]
export const provvedimentiFieldsCols: string[] = [
  "dataInserimento",
  "datiRegistro",
  "oggetto",
  "articolazione",
  "dataAdozione",
  "tipoProvvedimento",
  "dataUltimaModifica"
]
export const profiloCommittenteFieldsCols: string[] = [
  "registro",
  "numeroRegistro",
  "annoRegistro",
  "dataRegistro",
  "dataPubblicazioneProfCom", 
  "oggetto",
  "articolazione",
  "dataUltimaModifica"
]
export const registroAccessiFieldsCols: string[] = [
  "datiPubblicazione",
  "tipoProcedimento",
  "articolazione2", // UO precedente
  "attoDiIniziativa",// Atto di iniziativa 
  "dataIniziativa", // Del
  "oggetto",
  "controinteressati",
  "esito",
  "provvedimentoFinale",
  "dataChiusura",// Del
  "motivazione"
]

export const colsCSV: any[] = [
  {
    field: (x)=>{
      // var dom = new DOMParser().parseFromString(x.oggetto, 'text/html')
      // return  dom.body.innerText.replace(/\n/g," ");
      const tmp = document.createElement('DIV');
      tmp.innerHTML = x.oggetto;
      return (tmp.textContent || tmp.innerText || '').replace(/[\n,]/g," ");
    },
    header: "Oggetto",
    fieldType: "string",
    fieldId: "oggetto"
  },
  {
    field: "datiPubblicazioneVisualizzazione",
    header: "Dati Pubblicazione",
    fieldType: "string",
    fieldId: "datiPubblicazione"
  },
  {
    field: "articolazione",
    header: "Articolazione",
    fieldType: "string",
    fieldId: "articolazione"
  },
  {
    field: "numeroPubblicazione",
    header: "Numero pubblicazione",
    fieldType: "number",
    fieldId: "numeroPubblicazione"
  },
  {
    field: "annoPubblicazione",
    header: "Anno pubblicazione",
    fieldType: "number",
    fieldId: "annoPubblicazione"
  },
  {
    field: (doc) => {
      return doc.dataInserimento ? Utils.dateFormatter(doc.dataInserimento) : "";
    },
    header: "Data Inserimento",
    fieldType:  "date",
    fieldId: "dataInserimento"
  },
  {
    field: (doc) => {
      return doc.dataEsecutivitaVisualizzazione ? Utils.dateFormatter(doc.dataEsecutivitaVisualizzazione) : "";
    },
    header: "Data Esecutività",
    fieldType: "date",
    fieldId: "dataEsecutivita"
  },
  {
    field: "datiRegistroVisualizzazione",
    header: "Dati Registro",
    fieldType: "number",
    fieldId: "datiRegistro"
  },
  { 
    field: (doc) => {
      return doc.dataAdozioneVisualizzazione ? Utils.dateFormatter(doc.dataAdozioneVisualizzazione) : "";
    },
    header: "Data Adozione",
    fieldType: "date",
    fieldId: "dataAdozione"
  },
  {
    field: "provvedimento.tipoProvvedimento",
    header: "Tipo Provvedimento",
    fieldType: "string",
    fieldId: "tipoProvvedimento"
  },
  { 
    field: (doc) => {
      return doc.dataUltimaModifica ? Utils.dateFormatter(doc.dataUltimaModifica) : "";
    },
    header: "Data Ultima Modifica",
    fieldType:  "date",
    fieldId: "dataUltimaModifica"
  },
  {
    field: "registroVisualizzazione",
    header: "Registro", 
    fieldType: "object",
    fieldId: "registro"
  },
  {
    field: "esecutivitaVisualizzazione",
    header: "Esecutività", 
    fieldType: "string", 
    fieldId: "esecutivita"
  },
  {
    field: "numeroRegistroVisualizzazione",
    header: "Numero registro", 
    fieldType: "string", 
    fieldId: "numeroRegistro"
  },
  {
    field: "annoRegistroVisualizzazione",
    header: "Anno registro", 
    fieldType: "string", 
    fieldId: "annoRegistro"
  },
  { 
    field: (doc) => {
      return doc.dataRegistrazioneVisualizzazione ? Utils.dateFormatter(doc.dataRegistrazioneVisualizzazione) : "";
    },
    header: "Data registrazione", 
    fieldType:  "date",
    fieldId: "dataRegistro"
  },
  {
    field: (doc) => {
      return doc.dataInserimento ? Utils.dateFormatter(doc.dataInserimento) : "";
    },
    header: "Data pubblicazione", 
    fieldType:  "date",
    fieldId: "dataPubblicazioneProfCom"
  },
  {
    field: (doc) => {
      return doc.dataInizioPubblicazioneVisualizzazione ? Utils.dateFormatter(doc.dataInizioPubblicazioneVisualizzazione) : "";
    },
    header: "Data pubblicazione", 
    fieldType:  "date",
    fieldId: "dataInizioPubblicazione"
  },
  {
    field: (doc) => {
      return doc.dataFinePubblicazioneVisualizzazione ? Utils.dateFormatter(doc.dataFinePubblicazioneVisualizzazione) : "";
    },
    header: "Data fine pubblicazione", 
    fieldType: "date",
    fieldId: "dataFinePubblicazione" 
  },
  {
    field: "registroAccessi.tipoProcedimento",
    header: "Tipo Procedimento",
    fieldType: "string",
    fieldId: "tipoProcedimento"
  },
  {
    field: "articolazione",
    header: "UO Procedente",
    fieldType: "string",
    fieldId: "articolazione2"
  },
  {
    field: "attoDiIniziativaVisualizzazione",
    header: "Atto di iniziativa",
    fieldType: "string",
    fieldId: "attoDiIniziativa"
  },
  {
    field: (doc) => {
      return doc.dataIniziativaVisualizzazione ? Utils.dateFormatter(doc.dataIniziativaVisualizzazione) : "";
    },
    header: "Data Iniziativa", 
    fieldType: "date",
    fieldId: "dataIniziativa" 
  },
  {
    field: "registroAccessi.controinteressati",
    header: "Controinteressati",
    fieldType: "boolean",
    fieldId: "controinteressati"
  },
  {
    field: "registroAccessi.esito",
    header: "Esito",
    fieldType: "string",
    fieldId: "esito"
  },
  {
    field: "provvedimentoFinaleVisualizzazione",
    header: "Provvedimento Finale",
    fieldType: "string",
    fieldId: "provvedimentoFinale"
  },
  {
    field: (doc) => {
      return doc.dataChiusuraVisualizzazione ? Utils.dateFormatter(doc.dataChiusuraVisualizzazione) : "";
    },
    header: "Data Chiusura", 
    fieldType: "date",
    fieldId: "dataChiusura" 
  },
  {
    //field: "registroAccessi.motivazione",
    field: (x)=>{
      // var dom = new DOMParser().parseFromString(x.oggetto, 'text/html')
      // return  dom.body.innerText.replace(/\n/g," ");
      const tmp = document.createElement('DIV');
      tmp.innerHTML = x.registroAccessi.motivazione;
      return (tmp.textContent || tmp.innerText || '').replace(/[\n,]/g," ");
    },
    header: "Motivazione",
    fieldType: "string",
    fieldId: "motivazione"
  },
  
]

export const registroFields = [
  { value: "", nome: "TUTTI"},
  { value: Registro.PG, nome: "Protocollo generale"},
  { value: Registro.DELI, nome: "Deliberazioni"},
  { value: Registro.DETE, nome: "Determinazioni"}
]
export const registroFields2 = [
  /* { value: null, nome: "TUTTI"}, */
  { value: [Registro.PG], nome: "Protocollo generale"},
  { value: [Registro.DELI], nome: "Deliberazioni"},
  { value: [Registro.DETE], nome: "Determinazioni"}
]



export const codiceEsitiFields = [
  { 
    value: [ 
      CodiceProfiloCommittente.Esito_di_procedura_soprasoglia_dete,
      CodiceProfiloCommittente.Esito_di_procedura_cumulativa_soprasoglia_dete,
    ],
    nome: CodiceName.Soprasoglia
  },
  { 
    value: [
      CodiceProfiloCommittente.Esito_di_procedura_sottosoglia_dete,
      CodiceProfiloCommittente.Esito_di_procedura_cumulativa_sottosoglia_dete
    ],
    nome: CodiceName.Sottosoglia
  },
  { 
    value: [
    CodiceProfiloCommittente.Esito_di_procedura_cumulativo,
    CodiceProfiloCommittente.Esito_di_procedura_40000_dete,
    CodiceProfiloCommittente.Esito_di_procedura_cumulativa_40000_dete,
    CodiceProfiloCommittente.Esito_di_procedura_procton
    ],
     nome: CodiceName.MilaEuro
  }
]
export const esitoFields = [
  { value: EsitoIter.ACCOLTO, nome: "Accolto" },
  { value: EsitoIter.RIFIUTO_PARZIALE, nome: "Rifiuto Parziale" },
  { value: EsitoIter.RIFIUTO_TOTALE, nome: "Rifiuto Totale" },
  { value: EsitoIter.RITIRATO, nome: "Ritirato" },
]

export const esecutivitaFields = [
  { value: Esecutivita.ANNULLATA, nome: "Annullata" },
  { value: Esecutivita.ESECUTIVA, nome: "Esecutiva" },
  { value: Esecutivita.IN_ATTESA, nome: "In Attesa" },
  { value: Esecutivita.NON_ESECUTIVA, nome: "Non Esecutiva" },
]



