import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { Albo } from './albi-constants';
import { URL_MAP } from './url-map-constants';
import { ParametersLoadService } from './services/parameters-load.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'albi-ui';
  public albo: Albo;
  public displayErrorPage: boolean = false;

  constructor(private parametersLoadService: ParametersLoadService) {

  }

  ngOnInit(): void {
    window.addEventListener('popstate', function (event) { 
      window.location.reload(); 
    });
    let urlAlbo = window.location.href;
    urlAlbo = this.cleasingUrl(urlAlbo);
    if(URL_MAP[urlAlbo]!=undefined){ 
      this.displayErrorPage = false;
      this.albo = URL_MAP[urlAlbo];
      this.parametersLoadService.loadParameters(this.albo);
    } else {
      this.displayErrorPage = true; 
    }
       
  }



  public cleasingUrl(urlDaModificare: string): string {
    // urlDaModificare = urlDaModificare.replace("http:\/\/", "https:\/\/");
    urlDaModificare = urlDaModificare.replace(/\/+$/gm, "")
    urlDaModificare = urlDaModificare.replace(/(\w)(#\/)/gm, "$1/#/")
    return urlDaModificare;
  }

}
