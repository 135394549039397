import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

import {MessageService, ConfirmationService } from 'primeng/api';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PubblicazioniListComponent } from './container/pubblicazioni-list/pubblicazioni-list.component';
import { ContainerComponent } from './container/container.component';
import { HttpClientModule } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { PrimeNgModule } from './primeng.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { PubblicazioneDetailComponent } from './container/pubblicazione-detail/pubblicazione-detail.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { Router,NavigationEnd  } from '@angular/router';

@NgModule({
  declarations: [
    AppComponent,
    PubblicazioniListComponent,
    ContainerComponent,
    PubblicazioneDetailComponent,
    PageNotFoundComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    // AppRoutingModule,
    TableModule,
    ToastModule,
    ConfirmPopupModule,
    ConfirmDialogModule,
    HttpClientModule,
    PrimeNgModule,
    FormsModule,
    ReactiveFormsModule,
    ProgressSpinnerModule
  ],
  providers: [
    MessageService,
    DatePipe,
    ConfirmationService],
  bootstrap: [AppComponent]
})
export class AppModule { }
