import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Albo, TipiTabellaAlbi } from 'src/app/albi-constants';
import { Allegato } from 'src/app/entities/Allegato';
import { Pubblicazione } from 'src/app/entities/Pubblicazione';
import { CodiceProfiloCommittente } from 'src/app/entities/PubblicazioneCommittente';
import { AllegatoService } from 'src/app/services/allegato.service';
import { alboFields, profComFields } from './pubblicazione-detail-constants';

@Component({
  selector: 'app-pubblicazione-detail',
  templateUrl: './pubblicazione-detail.component.html',
  styleUrls: ['./pubblicazione-detail.component.scss']
})
export class PubblicazioneDetailComponent implements OnInit {

  _pubElement: Pubblicazione;
  get pubElement(): Pubblicazione {
    return this._pubElement;
  }
  @Input() set pubElement(value: Pubblicazione) {
    this._pubElement = value;
    this.publicazioneChanged();
    if (this.fieldsToUse && this._pubElement.pubblicazioneCommittente) {
      this.checkAndUpdateFieldsToUse();
    }
  }
  @Input('albo') albo: Albo;
  @Output('closeRightPanel') closeRightPanel = new EventEmitter();
  @ViewChild("anteprima") private anteprima: ElementRef;


  public allegati: Allegato[];
  allegatoSelezionato: any;
  public noAnteprima: boolean = false;
  public rowChanged: boolean;
  public fieldsToUse: any[];

  constructor(
    private allegatoService: AllegatoService
  ) { }

  ngOnInit(): void {
    this.loadFieldsToUse();
  }
  public loadFieldsToUse() {
    switch (this.albo.tipo) {
      case TipiTabellaAlbi.ALBO:
        this.fieldsToUse = alboFields;
        this.filterAllegatoFromPeriodo();
        break;
      case TipiTabellaAlbi.PROFILO_COMMITTENTE:
        this.fieldsToUse = profComFields;
        this.checkAndUpdateFieldsToUse();
        break;
    }
  }
  /* 
    This is from the Old ALBO don't know which fields should take the neccessery names; 
    Cambio le etichette per questi due campi se la pubblicazione viene da Pico
  */
  public checkAndUpdateFieldsToUse() {
    if (this.pubElement.pubblicazioneCommittente.codiceProfiloCommittente === CodiceProfiloCommittente.Esito_di_procedura_procton ||
      this.pubElement.pubblicazioneCommittente.codiceProfiloCommittente === 'nota_a_contrarre_procton') {
      this.fieldsToUse.find(c => c.field == 'cig').header = 'CIG';
      this.fieldsToUse.find(c => c.field == 'importo').header = 'Importo senza IVA';
    } else {
      this.fieldsToUse.find(c => c.field == 'cig').header = 'CIG Accordo quadro';
      this.fieldsToUse.find(c => c.field == 'importo').header = 'Importo';
    }
  }
  /* 
    Function to initialize Anteprima when the row is selected/changed
  */
  public publicazioneChanged() {
    this.rowChanged = true;
    if (this.pubElement.allegatiList.length != 0) {
      this.allegatoSelezionato = this.pubElement.allegatiList[0];
      this.setAnteprimaUrl();
    }
  }
  //function that might be used in future!
  //public downloadAllegati(allegatiEl: Allegato){
  // console.log(allegatiEl);
  //  }

  //function to display the allegato when selected
  public allegatoSelected(event: any) {
    console.log("string", event);
    if (event && event.value) {
      this.allegatoSelezionato = event.value;
      this.setAnteprimaUrl();
    } else {
      this.allegatoSelezionato = null;
    }
  }

  fullscreen(event: any) {
    const iframeElement: any = this.anteprima.nativeElement;
    const fullScreenFunction = iframeElement.requestFullscreen || iframeElement.webkitRequestFullscreen || iframeElement.mozRequestFullScreen || iframeElement.msRequestFullscreen;
    fullScreenFunction.call(iframeElement);
  }


  public setAnteprimaUrl() {
    /* if ( this.attivitaSelezionata.idApplicazione.id === "dete" && this.attivitaSelezionata.descrizione === "Bozza" ) {
      this.noAnteprima = true;
    } else { */
    this.rowChanged = false;
    this.noAnteprima = false;
    this.allegatoService
      .downloadAllegatoFile(this.allegatoSelezionato)
      .subscribe({
        next: (file) => {
          //console.log(file);
          this.anteprima.nativeElement.src = URL.createObjectURL(file);
        },
        error: () => {
          this.noAnteprima = true;
        }
      });
    /*  } */
  }

  public filterAllegatoFromPeriodo(){
    this.pubElement.allegatiList = this.pubElement.allegatiList.filter(al => {
      switch(al.tipoAllegato){
        case "RELATA":
          if (this.pubElement.periodoPubblicazioneList.find(pr => pr['fk_idRelata'].id === al.id)) {
            return true;
          }else{
            return false;
          }
        case "STAMPA_UNICA":
          return true;
      }
    })
  }

}


