import { NextSdrEntity,  NextSDRDateTypes, NextSDRDateInformation } from "@bds/next-sdr";
import { Allegato } from "./Allegato";
import { Applicazione } from "./Applicazione";
import { Azienda } from "./Azienda";
import { PeriodoPubblicazione } from "./PeriodoPubblicazione";
import { Provvedimento } from "./Provvedimento";
import { PubblicazioneCommittente } from "./PubblicazioneCommittente";
import { RegistroAccessi } from "./RegistroAccessi";
import { TipoDocumento } from "./TipoDocumento";

export class Pubblicazione implements NextSdrEntity {
    id: number;
    oggetto: string;
    articolazione: string;
    numeroPubblicazione: number;
    annoPubblicazione: number;
    dataInserimento: Date;
    dataUltimaModifica: Date;
    tipologiaAlbo: TipologiaAlbo;
    sottoTipologiaAlbo: SottotipologiaAlbo;
    idApplicazione: Applicazione;
    idProvvedimento: Provvedimento;
    idAzienda: Azienda;
    idRegistroAccessi: RegistroAccessi;
    periodoPubblicazioneList: PeriodoPubblicazione[];
    tipiDocumentiList: TipoDocumento[];
    pubblicazioneCommittente: PubblicazioneCommittente;
    allegatiList: Allegato[];
    version: string;

    nextSdrDateInformation: NextSDRDateInformation = {
        dataInserimento: NextSDRDateTypes.ZonedDateTime,
        dataUltimaModifica: NextSDRDateTypes.ZonedDateTime
    };
}
export enum TipologiaAlbo {
	ALBO = "ALBO",
    PROFILO_COMMITTENTE = "PROFILO_COMMITTENTE" ,
	REGISTRO_ACCESSI = "REGISTRO_ACCESSI",
	PROVVEDIMENTI = "PROVVEDIMENTI"
}
    
export enum SottotipologiaAlbo {
	DIRIGENTI,
    POLITICI,
	ATTI,
	PROCEDURE,
    ESITI,
    DETERMINE,
    ATTI_DI_ESECUZIONE
}
    
