import { Albo, AmbientiTabellaAlbi, SpecificheTabellaAlbi, TipiTabellaAlbi } from "./albi-constants"
import { albiMainFieldsCols } from "./container/pubblicazioni-list/pubblicazioni-list-constants"


export const URL_BACKEND = {
    LOCALHOST: "http://localhost:10010/albi-api/resources",
    SVILUPPO: "http://localhost:10010/albi-api/resources",
    TEST: "http://localhost:10010/albi-api/resources",
    PROD: "http://localhost:10010/albi-api/resources"
}

export const URL_MAP = {
    "http://localhost:4200" :  {
        tipo: TipiTabellaAlbi.ALBO,
        specifica: null,
        ambiente: AmbientiTabellaAlbi.SVILUPPO,
        backend: URL_BACKEND.LOCALHOST,
        azienda: "auslbo",
    } as Albo,
    "http://localhost:4200/index_dirigenti.html" :  {
        tipo: TipiTabellaAlbi.PROVVEDIMENTI,
        specifica: SpecificheTabellaAlbi.DIRIGENTI,
        ambiente: AmbientiTabellaAlbi.SVILUPPO,
        backend: URL_BACKEND.LOCALHOST,
        azienda: "auslbo",
    }as Albo,
    "http://localhost:4200/index_politici.html" : {
        tipo: TipiTabellaAlbi.PROVVEDIMENTI,
        specifica: SpecificheTabellaAlbi.POLITICI,
        ambiente: AmbientiTabellaAlbi.SVILUPPO,
        backend: URL_BACKEND.LOCALHOST,
        azienda: "auslbo",
    }as Albo,
    "http://localhost:4200/profilo-committente/#/atti" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.ATTI,
        ambiente: AmbientiTabellaAlbi.SVILUPPO,
        backend: URL_BACKEND.LOCALHOST,
        azienda: "auslbo",
    }as Albo,
    "http://localhost:4200/profilo-committente/#/determine" : {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.DETERMINE,
        ambiente: AmbientiTabellaAlbi.SVILUPPO,
        backend: URL_BACKEND.LOCALHOST,
        azienda: "auslbo",
    }as Albo,
    "http://localhost:4200/profilo-committente/#/procedure" : {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.PROCEDURE,
        ambiente: AmbientiTabellaAlbi.SVILUPPO,
        backend: URL_BACKEND.LOCALHOST,
        azienda: "auslbo",
    }as Albo,
    "http://localhost:4200/profilo-committente/#/esiti" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.ESITI,
        ambiente: AmbientiTabellaAlbi.SVILUPPO,
        backend: URL_BACKEND.LOCALHOST,
        azienda: "auslbo",
    }as Albo,
    "http://localhost:4200/profilo-committente/#/atti-di-esecuzione" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.ATTI_DI_ESECUZIONE,
        ambiente: AmbientiTabellaAlbi.SVILUPPO,
        backend: URL_BACKEND.LOCALHOST,
        azienda: "auslbo",
    }as Albo,
    "http://localhost:4200/registro-accessi" :  {
        tipo: TipiTabellaAlbi.REGISTRO_ACCESSI,
        specifica: null,
        ambiente: AmbientiTabellaAlbi.SVILUPPO,
        backend: URL_BACKEND.LOCALHOST,
        azienda: "auslbo",
    }as Albo,


    //GDML URL
    "https://gdml.internal.ausl.bologna.it/albi" :  {
        tipo: TipiTabellaAlbi.ALBO,
        specifica: null,
        ambiente: AmbientiTabellaAlbi.SVILUPPO,
        backend: URL_BACKEND.SVILUPPO,
        azienda: "auslbo",
    } as Albo,
    "https://gdml.internal.ausl.bologna.it/albi/index_dirigenti.html" :  {
        tipo: TipiTabellaAlbi.PROVVEDIMENTI,
        specifica: SpecificheTabellaAlbi.DIRIGENTI,
        ambiente: AmbientiTabellaAlbi.SVILUPPO,
        backend: URL_BACKEND.SVILUPPO,
        azienda: "auslbo",
    }as Albo,
    "https://gdml.internal.ausl.bologna.it/albi/index_politici.html" : {
        tipo: TipiTabellaAlbi.PROVVEDIMENTI,
        specifica: SpecificheTabellaAlbi.POLITICI,
        ambiente: AmbientiTabellaAlbi.SVILUPPO,
        backend: URL_BACKEND.SVILUPPO,
        azienda: "auslbo",
    }as Albo,
    "https://gdml.internal.ausl.bologna.it/albi/profilo-committente/#/atti" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.ATTI,
        ambiente: AmbientiTabellaAlbi.SVILUPPO,
        backend: URL_BACKEND.SVILUPPO,
        azienda: "auslbo",
    }as Albo,
    "https://gdml.internal.ausl.bologna.it/albi/profilo-committente/#/determine" : {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.DETERMINE,
        ambiente: AmbientiTabellaAlbi.SVILUPPO,
        backend: URL_BACKEND.SVILUPPO,
        azienda: "auslbo",
    }as Albo,
    "https://gdml.internal.ausl.bologna.it/albi/profilo-committente/#/procedure" : {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.PROCEDURE,
        ambiente: AmbientiTabellaAlbi.SVILUPPO,
        backend: URL_BACKEND.SVILUPPO,
        azienda: "auslbo",
    }as Albo,
    "https://gdml.internal.ausl.bologna.it/albi/profilo-committente/#/esiti" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.ESITI,
        ambiente: AmbientiTabellaAlbi.SVILUPPO,
        backend: URL_BACKEND.SVILUPPO,
        azienda: "auslbo",
    }as Albo,
    "https://gdml.internal.ausl.bologna.it/albi/profilo-committente/#/atti-di-esecuzione" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.ATTI_DI_ESECUZIONE,
        ambiente: AmbientiTabellaAlbi.SVILUPPO,
        backend: URL_BACKEND.SVILUPPO,
        azienda: "auslbo",
    }as Albo,
    "https://gdml.internal.ausl.bologna.it/albi/registro-accessi" :  {
        tipo: TipiTabellaAlbi.REGISTRO_ACCESSI,
        specifica: null,
        ambiente: AmbientiTabellaAlbi.SVILUPPO,
        backend: URL_BACKEND.SVILUPPO,
        azienda: "auslbo",
    }as Albo,


    //AUSLBO TEST URL
    "https://arena.internal.ausl.bologna.it/albi/albo" : {
        tipo: TipiTabellaAlbi.ALBO,
        specifica:  null,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "auslbo",
    }as Albo,
    "https://arena.internal.ausl.bologna.it/albi/albo/index_dirigenti.html" : {
        tipo: TipiTabellaAlbi.PROVVEDIMENTI,
        specifica: SpecificheTabellaAlbi.DIRIGENTI,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "auslbo",
    }as Albo,
    "https://arena.internal.ausl.bologna.it/albi/albo/index_politici.html" : {
        tipo: TipiTabellaAlbi.PROVVEDIMENTI,
        specifica: SpecificheTabellaAlbi.POLITICI,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "auslbo",
    }as Albo,
    "https://arena.internal.ausl.bologna.it/albi/profilo-committente/#/atti" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.ATTI,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "auslbo",
    }as Albo,
    "https://arena.internal.ausl.bologna.it/albi/profilo-committente/#/procedure" : {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.PROCEDURE,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "auslbo",
    }as Albo,
    "https://arena.internal.ausl.bologna.it/albi/profilo-committente/#/esiti" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.ESITI,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "auslbo",
    }as Albo,
    "https://arena.internal.ausl.bologna.it/albi/profilo-committente/#/determine" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.DETERMINE,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "auslbo",
    }as Albo,
    "https://arena.internal.ausl.bologna.it/albi/profilo-committente/#/atti-di-esecuzione" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.ATTI_DI_ESECUZIONE,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "auslbo",
    }as Albo,
    "https://arena.internal.ausl.bologna.it/albi/registro-accessi" :  {
        tipo: TipiTabellaAlbi.REGISTRO_ACCESSI,
        specifica: null,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "auslbo",
    }as Albo,

    //AZERO TEST URL
    "https://albi-test.azero.veneto.it/albi/albo" : {
        tipo: TipiTabellaAlbi.ALBO,
        specifica:  null,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "azero",
    }as Albo,
    "https://albi-test.azero.veneto.it/albi/albo/index_dirigenti.html" : {
        tipo: TipiTabellaAlbi.PROVVEDIMENTI,
        specifica: SpecificheTabellaAlbi.DIRIGENTI,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "azero",
    }as Albo,
    "https://albi-test.azero.veneto.it/albi/albo/index_politici.html" : {
        tipo: TipiTabellaAlbi.PROVVEDIMENTI,
        specifica: SpecificheTabellaAlbi.POLITICI,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "azero",
    }as Albo,
    "https://albi-test.azero.veneto.it/albi/profilo-committente/#/atti" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.ATTI,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "azero",
    }as Albo,
    "https://albi-test.azero.veneto.it/albi/profilo-committente/#/procedure" : {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.PROCEDURE,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "azero",
    }as Albo,
    "https://albi-test.azero.veneto.it/albi/profilo-committente/#/esiti" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.ESITI,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "azero",
    }as Albo,
    "https://albi-test.azero.veneto.it/albi/profilo-committente/#/determine" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.DETERMINE,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "azero",
    }as Albo,
    "https://albi-test.azero.veneto.it/albi/profilo-committente/#/atti-di-esecuzione" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.ATTI_DI_ESECUZIONE,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "azero",
    }as Albo,
    "https://albi-test.azero.veneto.it/albi/registro-accessi" :  {
        tipo: TipiTabellaAlbi.REGISTRO_ACCESSI,
        specifica: null,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "azero",
    }as Albo,

    //AOVR TEST URL
    "https://albi-test.aovr.veneto.it/albi/albo" : {
        tipo: TipiTabellaAlbi.ALBO,
        specifica:  null,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "aouivr",
    }as Albo,
    "https://albi-test.aovr.veneto.it/albi/albo/index_dirigenti.html" : {
        tipo: TipiTabellaAlbi.PROVVEDIMENTI,
        specifica: SpecificheTabellaAlbi.DIRIGENTI,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "aouivr",
    }as Albo,
    "https://albi-test.aovr.veneto.it/albi/albo/index_politici.html" : {
        tipo: TipiTabellaAlbi.PROVVEDIMENTI,
        specifica: SpecificheTabellaAlbi.POLITICI,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "aouivr",
    }as Albo,
    "https://albi-test.aovr.veneto.it/albi/profilo-committente/#/atti" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.ATTI,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "aouivr",
    }as Albo,
    "https://albi-test.aovr.veneto.it/albi/profilo-committente/#/procedure" : {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.PROCEDURE,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "aouivr",
    }as Albo,
    "https://albi-test.aovr.veneto.it/albi/profilo-committente/#/esiti" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.ESITI,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "aouivr",
    }as Albo,
    "https://albi-test.aovr.veneto.it/albi/profilo-committente/#/determine" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.DETERMINE,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "aouivr",
    }as Albo,
    "https://albi-test.aovr.veneto.it/albi/profilo-committente/#/atti-di-esecuzione" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.ATTI_DI_ESECUZIONE,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "aouivr",
    }as Albo,
    "https://albi-test.aovr.veneto.it/albi/registro-accessi" :  {
        tipo: TipiTabellaAlbi.REGISTRO_ACCESSI,
        specifica: null,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "aouivr",
    }as Albo,

     //AOSPBO TEST URL
     "https://babeltest-aospbo.avec.emr.it/albo" : {
        tipo: TipiTabellaAlbi.ALBO,
        specifica:  null,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "aospbo",
    }as Albo,
    "https://babeltest-aospbo.avec.emr.it/albo/index_dirigenti.html" : {
        tipo: TipiTabellaAlbi.PROVVEDIMENTI,
        specifica: SpecificheTabellaAlbi.DIRIGENTI,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "aospbo",
    }as Albo,
    "https://babeltest-aospbo.avec.emr.it/albo/index_politici.html" : {
        tipo: TipiTabellaAlbi.PROVVEDIMENTI,
        specifica: SpecificheTabellaAlbi.POLITICI,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "aospbo",
    }as Albo,
    "https://babeltest-aospbo.avec.emr.it/profilo-committente/#/atti" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.ATTI,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "aospbo",
    }as Albo,
    "https://babeltest-aospbo.avec.emr.it/profilo-committente/#/procedure" : {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.PROCEDURE,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "aospbo",
    }as Albo,
    "https://babeltest-aospbo.avec.emr.it/profilo-committente/#/esiti" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.ESITI,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "aospbo",
    }as Albo,
    "https://babeltest-aospbo.avec.emr.it/profilo-committente/#/determine" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.DETERMINE,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "aospbo",
    }as Albo,
    "https://babeltest-aospbo.avec.emr.it/profilo-committente/#/atti-di-esecuzione" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.ATTI_DI_ESECUZIONE,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "aospbo",
    }as Albo,
    "https://babeltest-aospbo.avec.emr.it/registro-accessi" :  {
        tipo: TipiTabellaAlbi.REGISTRO_ACCESSI,
        specifica: null,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "aospbo",
    }as Albo,

    //IOR TEST URL
    "https://babeltest-ior.avec.emr.it/albo" : {
        tipo: TipiTabellaAlbi.ALBO,
        specifica:  null,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "ior",
    }as Albo,
    "https://babeltest-ior.avec.emr.it/albo/index_dirigenti.html" : {
        tipo: TipiTabellaAlbi.PROVVEDIMENTI,
        specifica: SpecificheTabellaAlbi.DIRIGENTI,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "ior",
    }as Albo,
    "https://babeltest-ior.avec.emr.it/albo/index_politici.html" : {
        tipo: TipiTabellaAlbi.PROVVEDIMENTI,
        specifica: SpecificheTabellaAlbi.POLITICI,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "ior",
    }as Albo,
    "https://babeltest-ior.avec.emr.it/profilo-committente/#/atti" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.ATTI,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "ior",
    }as Albo,
    "https://babeltest-ior.avec.emr.it/profilo-committente/#/procedure" : {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.PROCEDURE,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "ior",
    }as Albo,
    "https://babeltest-ior.avec.emr.it/profilo-committente/#/esiti" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.ESITI,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "ior",
    }as Albo,
    "https://babeltest-ior.avec.emr.it/profilo-committente/#/determine" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.DETERMINE,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "ior",
    }as Albo,
    "https://babeltest-ior.avec.emr.it/profilo-committente/#/atti-di-esecuzione" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.ATTI_DI_ESECUZIONE,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "ior",
    }as Albo,
    "https://babeltest-ior.avec.emr.it/registro-accessi" :  {
        tipo: TipiTabellaAlbi.REGISTRO_ACCESSI,
        specifica: null,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "ior",
    }as Albo,
     
    //AUSLIM TEST URL
    "https://babeltest-auslim.avec.emr.it/albo" : {
        tipo: TipiTabellaAlbi.ALBO,
        specifica:  null,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "auslim",
    }as Albo,
    "https://babeltest-auslim.avec.emr.it/albo/index_dirigenti.html" : {
        tipo: TipiTabellaAlbi.PROVVEDIMENTI,
        specifica: SpecificheTabellaAlbi.DIRIGENTI,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "auslim",
    }as Albo,
    "https://babeltest-auslim.avec.emr.it/albo/index_politici.html" : {
        tipo: TipiTabellaAlbi.PROVVEDIMENTI,
        specifica: SpecificheTabellaAlbi.POLITICI,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "auslim",
    }as Albo,
    "https://babeltest-auslim.avec.emr.it/profilo-committente/#/atti" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.ATTI,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "auslim",
    }as Albo,
    "https://babeltest-auslim.avec.emr.it/profilo-committente/#/procedure" : {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.PROCEDURE,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "auslim",
    }as Albo,
    "https://babeltest-auslim.avec.emr.it/profilo-committente/#/esiti" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.ESITI,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "auslim",
    }as Albo,
    "https://babeltest-auslim.avec.emr.it/profilo-committente/#/determine" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.DETERMINE,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "auslim",
    }as Albo,
    "https://babeltest-auslim.avec.emr.it/profilo-committente/#/atti-di-esecuzione" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.ATTI_DI_ESECUZIONE,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "auslim",
    }as Albo,
    "https://babeltest-auslim.avec.emr.it/registro-accessi" :  {
        tipo: TipiTabellaAlbi.REGISTRO_ACCESSI,
        specifica: null,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "auslim",
    }as Albo,
    
    //AUSLFE TEST URL
    "https://babeltest-auslfe.avec.emr.it/albo" : {
        tipo: TipiTabellaAlbi.ALBO,
        specifica:  null,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "auslfe",
    }as Albo,
    "https://babeltest-auslfe.avec.emr.it/albo/index_dirigenti.html" : {
        tipo: TipiTabellaAlbi.PROVVEDIMENTI,
        specifica: SpecificheTabellaAlbi.DIRIGENTI,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "auslfe",
    }as Albo,
    "https://babeltest-auslfe.avec.emr.it/albo/index_politici.html" : {
        tipo: TipiTabellaAlbi.PROVVEDIMENTI,
        specifica: SpecificheTabellaAlbi.POLITICI,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "auslfe",
    }as Albo,
    "https://babeltest-auslfe.avec.emr.it/profilo-committente/#/atti" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.ATTI,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "auslfe",
    }as Albo,
    "https://babeltest-auslfe.avec.emr.it/profilo-committente/#/procedure" : {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.PROCEDURE,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "auslfe",
    }as Albo,
    "https://babeltest-auslfe.avec.emr.it/profilo-committente/#/esiti" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.ESITI,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "auslfe",
    }as Albo,
    "https://babeltest-auslfe.avec.emr.it/profilo-committente/#/determine" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.DETERMINE,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "auslfe",
    }as Albo,
    "https://babeltest-auslfe.avec.emr.it/profilo-committente/#/atti-di-esecuzione" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.ATTI_DI_ESECUZIONE,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "auslfe",
    }as Albo,
    "https://babeltest-auslfe.avec.emr.it/registro-accessi" :  {
        tipo: TipiTabellaAlbi.REGISTRO_ACCESSI,
        specifica: null,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "auslfe",
    }as Albo,

    //AOSPFE TEST URL
    "https://babeltest-aospfe.avec.emr.it/albo" : {
        tipo: TipiTabellaAlbi.ALBO,
        specifica:  null,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "aospfe",
    }as Albo,
    "https://babeltest-aospfe.avec.emr.it/albo/index_dirigenti.html" : {
        tipo: TipiTabellaAlbi.PROVVEDIMENTI,
        specifica: SpecificheTabellaAlbi.DIRIGENTI,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "aospfe",
    }as Albo,
    "https://babeltest-aospfe.avec.emr.it/albo/index_politici.html" : {
        tipo: TipiTabellaAlbi.PROVVEDIMENTI,
        specifica: SpecificheTabellaAlbi.POLITICI,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "aospfe",
    }as Albo,
    "https://babeltest-aospfe.avec.emr.it/profilo-committente/#/atti" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.ATTI,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "aospfe",
    }as Albo,
    "https://babeltest-aospfe.avec.emr.it/profilo-committente/#/procedure" : {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.PROCEDURE,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "aospfe",
    }as Albo,
    "https://babeltest-aospfe.avec.emr.it/profilo-committente/#/esiti" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.ESITI,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "aospfe",
    }as Albo,
    "https://babeltest-aospfe.avec.emr.it/profilo-committente/#/determine" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.DETERMINE,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "aospfe",
    }as Albo,
    "https://babeltest-aospfe.avec.emr.it/profilo-committente/#/atti-di-esecuzione" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.ATTI_DI_ESECUZIONE,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "aospfe",
    }as Albo,
    "https://babeltest-aospfe.avec.emr.it/registro-accessi" :  {
        tipo: TipiTabellaAlbi.REGISTRO_ACCESSI,
        specifica: null,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "aospfe",
    }as Albo,

    //AUSLPR TEST URL
    "https://babel-test.ausl.pr.it/albo" : {
        tipo: TipiTabellaAlbi.ALBO,
        specifica:  null,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "auslpr",
    }as Albo,
    "https://babel-test.ausl.pr.it/albo/index_dirigenti.html" : {
        tipo: TipiTabellaAlbi.PROVVEDIMENTI,
        specifica: SpecificheTabellaAlbi.DIRIGENTI,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "auslpr",
    }as Albo,
    "https://babel-test.ausl.pr.it/albo/index_politici.html" : {
        tipo: TipiTabellaAlbi.PROVVEDIMENTI,
        specifica: SpecificheTabellaAlbi.POLITICI,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "auslpr",
    }as Albo,
    "https://babel-test.ausl.pr.it/profilo-committente/#/atti" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.ATTI,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "auslpr",
    }as Albo,
    "https://babel-test.ausl.pr.it/profilo-committente/#/procedure" : {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.PROCEDURE,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "auslpr",
    }as Albo,
    "https://babel-test.ausl.pr.it/profilo-committente/#/esiti" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.ESITI,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "auslpr",
    }as Albo,
    "https://babel-test.ausl.pr.it/profilo-committente/#/determine" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.DETERMINE,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "auslpr",
    }as Albo,
    "https://babel-test.ausl.pr.it/profilo-committente/#/atti-di-esecuzione" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.ATTI_DI_ESECUZIONE,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "auslpr",
    }as Albo,
    "https://babel-test.ausl.pr.it/registro-accessi" :  {
        tipo: TipiTabellaAlbi.REGISTRO_ACCESSI,
        specifica: null,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "auslpr",
    }as Albo,
        
    //AOSPPR TEST URL
    "https://babeltest-aopr.avec.emr.it/albo" : {
        tipo: TipiTabellaAlbi.ALBO,
        specifica:  null,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "aosppr",
    }as Albo,
    "https://babeltest-aopr.avec.emr.it/albo/index_dirigenti.html" : {
        tipo: TipiTabellaAlbi.PROVVEDIMENTI,
        specifica: SpecificheTabellaAlbi.DIRIGENTI,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "aosppr",
    }as Albo,
    "https://babeltest-aopr.avec.emr.it/albo/index_politici.html" : {
        tipo: TipiTabellaAlbi.PROVVEDIMENTI,
        specifica: SpecificheTabellaAlbi.POLITICI,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "aosppr",
    }as Albo,
    "https://babeltest-aopr.avec.emr.it/profilo-committente/#/atti" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.ATTI,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "aosppr",
    }as Albo,
    "https://babeltest-aopr.avec.emr.it/profilo-committente/#/procedure" : {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.PROCEDURE,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "aosppr",
    }as Albo,
    "https://babeltest-aopr.avec.emr.it/profilo-committente/#/esiti" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.ESITI,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "aosppr",
    }as Albo,
    "https://babeltest-aopr.avec.emr.it/profilo-committente/#/determine" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.DETERMINE,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "aosppr",
    }as Albo,
    "https://babeltest-aopr.avec.emr.it/profilo-committente/#/atti-di-esecuzione" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.ATTI_DI_ESECUZIONE,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "aosppr",
    }as Albo,
    "https://babeltest-aopr.avec.emr.it/registro-accessi" :  {
        tipo: TipiTabellaAlbi.REGISTRO_ACCESSI,
        specifica: null,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "aosppr",
    }as Albo,

    //AUSLBO PROD URL
    "https://albonline.ausl.bologna.it/albi" : {
        tipo: TipiTabellaAlbi.ALBO,
        specifica:  null,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "auslbo",
    }as Albo,
    "https://trasparenza.ausl.bologna.it/albi/index_dirigenti.html" : {
        tipo: TipiTabellaAlbi.PROVVEDIMENTI,
        specifica: SpecificheTabellaAlbi.DIRIGENTI,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "auslbo",
    }as Albo,
    "https://trasparenza.ausl.bologna.it/albi/index_politici.html" : {
        tipo: TipiTabellaAlbi.PROVVEDIMENTI,
        specifica: SpecificheTabellaAlbi.POLITICI,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "auslbo",
    }as Albo,
    "https://trasparenza.ausl.bologna.it/albi/profilo-committente/#/atti" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.ATTI,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "auslbo",
    }as Albo,
    "https://trasparenza.ausl.bologna.it/albi/profilo-committente/#/procedure" : {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.PROCEDURE,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "auslbo",
    }as Albo,
    "https://trasparenza.ausl.bologna.it/albi/profilo-committente/#/esiti" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.ESITI,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "auslbo",
    }as Albo,
    "https://trasparenza.ausl.bologna.it/albi/profilo-committente/#/determine" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.DETERMINE,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "auslbo",
    }as Albo,
    "https://trasparenza.ausl.bologna.it/albi/profilo-committente/#/atti-di-esecuzione" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.ATTI_DI_ESECUZIONE,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "auslbo",
    }as Albo,
    "https://trasparenza.ausl.bologna.it/albi/registro-accessi" :  {
        tipo: TipiTabellaAlbi.REGISTRO_ACCESSI,
        specifica: null,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "auslbo",
    }as Albo,

     //AOSPBO PROD URL
     "https://albonline.aosp.bo.it/albi" : {
        tipo: TipiTabellaAlbi.ALBO,
        specifica:  null,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "aospbo",
    }as Albo,
    "https://trasparenza.aosp.bo.it/albi/index_dirigenti.html" : {
        tipo: TipiTabellaAlbi.PROVVEDIMENTI,
        specifica: SpecificheTabellaAlbi.DIRIGENTI,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "aospbo",
    }as Albo,
    "https://trasparenza.aosp.bo.it/albi/index_politici.html" : {
        tipo: TipiTabellaAlbi.PROVVEDIMENTI,
        specifica: SpecificheTabellaAlbi.POLITICI,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "aospbo",
    }as Albo,
    "https://trasparenza.aosp.bo.it/albi/profilo-committente/#/atti" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.ATTI,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "aospbo",
    }as Albo,
    "https://trasparenza.aosp.bo.it/albi/profilo-committente/#/procedure" : {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.PROCEDURE,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "aospbo",
    }as Albo,
    "https://trasparenza.aosp.bo.it/albi/profilo-committente/#/esiti" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.ESITI,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "aospbo",
    }as Albo,
    "https://trasparenza.aosp.bo.it/albi/profilo-committente/#/determine" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.DETERMINE,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "aospbo",
    }as Albo,
    "https://trasparenza.aosp.bo.it/albi/profilo-committente/#/atti-di-esecuzione" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.ATTI_DI_ESECUZIONE,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "aospbo",
    }as Albo,
    "https://trasparenza.aosp.bo.it/albi/registro-accessi" :  {
        tipo: TipiTabellaAlbi.REGISTRO_ACCESSI,
        specifica: null,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "aospbo",
    }as Albo,

    //IOR PROD URL
    "https://albonline.ior.it/albi" : {
        tipo: TipiTabellaAlbi.ALBO,
        specifica:  null,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "ior",
    }as Albo,
    "https://trasparenza.ior.it/albi/index_dirigenti.html" : {
        tipo: TipiTabellaAlbi.PROVVEDIMENTI,
        specifica: SpecificheTabellaAlbi.DIRIGENTI,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "ior",
    }as Albo,
    "https://trasparenza.ior.it/albi/index_politici.html" : {
        tipo: TipiTabellaAlbi.PROVVEDIMENTI,
        specifica: SpecificheTabellaAlbi.POLITICI,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "ior",
    }as Albo,
    "https://trasparenza.ior.it/albi/profilo-committente/#/atti" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.ATTI,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "ior",
    }as Albo,
    "https://trasparenza.ior.it/albi/profilo-committente/#/procedure" : {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.PROCEDURE,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "ior",
    }as Albo,
    "https://trasparenza.ior.it/albi/profilo-committente/#/esiti" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.ESITI,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "ior",
    }as Albo,
    "https://trasparenza.ior.it/albi/profilo-committente/#/determine" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.DETERMINE,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "ior",
    }as Albo,
    "https://trasparenza.ior.it/albi/profilo-committente/#/atti-di-esecuzione" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.ATTI_DI_ESECUZIONE,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "ior",
    }as Albo,
    "https://trasparenza.ior.it/albi/registro-accessi" :  {
        tipo: TipiTabellaAlbi.REGISTRO_ACCESSI,
        specifica: null,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "ior",
    }as Albo,
     
    //AUSLIM PROD URL
    "https://albonline.ausl.imola.bo.it/albi" : {
        tipo: TipiTabellaAlbi.ALBO,
        specifica:  null,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "auslim",
    }as Albo,
    "https://trasparenza.ausl.imola.bo.it/albi/index_dirigenti.html" : {
        tipo: TipiTabellaAlbi.PROVVEDIMENTI,
        specifica: SpecificheTabellaAlbi.DIRIGENTI,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "auslim",
    }as Albo,
    "https://trasparenza.ausl.imola.bo.it/albi/index_politici.html" : {
        tipo: TipiTabellaAlbi.PROVVEDIMENTI,
        specifica: SpecificheTabellaAlbi.POLITICI,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "auslim",
    }as Albo,
    "https://trasparenza.ausl.imola.bo.it/albi/profilo-committente/#/atti" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.ATTI,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "auslim",
    }as Albo,
    "https://trasparenza.ausl.imola.bo.it/albi/profilo-committente/#/procedure" : {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.PROCEDURE,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "auslim",
    }as Albo,
    "https://trasparenza.ausl.imola.bo.it/albi/profilo-committente/#/esiti" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.ESITI,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "auslim",
    }as Albo,
    "https://trasparenza.ausl.imola.bo.it/albi/profilo-committente/#/determine" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.DETERMINE,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "auslim",
    }as Albo,
    "https://trasparenza.ausl.imola.bo.it/albi/profilo-committente/#/atti-di-esecuzione" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.ATTI_DI_ESECUZIONE,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "auslim",
    }as Albo,
    "https://trasparenza.ausl.imola.bo.it/albi/registro-accessi" :  {
        tipo: TipiTabellaAlbi.REGISTRO_ACCESSI,
        specifica: null,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "auslim",
    }as Albo,
    
    //AUSLFE PROD URL
    "https://albonline.ausl.fe.it/albi" : {
        tipo: TipiTabellaAlbi.ALBO,
        specifica:  null,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "auslfe",
    }as Albo,
    "https://trasparenza.ausl.fe.it/albi/index_dirigenti.html" : {
        tipo: TipiTabellaAlbi.PROVVEDIMENTI,
        specifica: SpecificheTabellaAlbi.DIRIGENTI,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "auslfe",
    }as Albo,
    "https://trasparenza.ausl.fe.it/albi/index_politici.html" : {
        tipo: TipiTabellaAlbi.PROVVEDIMENTI,
        specifica: SpecificheTabellaAlbi.POLITICI,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "auslfe",
    }as Albo,
    "https://trasparenza.ausl.fe.it/albi/profilo-committente/#/atti" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.ATTI,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "auslfe",
    }as Albo,
    "https://trasparenza.ausl.fe.it/albi/profilo-committente/#/procedure" : {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.PROCEDURE,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "auslfe",
    }as Albo,
    "https://trasparenza.ausl.fe.it/albi/profilo-committente/#/esiti" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.ESITI,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "auslfe",
    }as Albo,
    "https://trasparenza.ausl.fe.it/albi/profilo-committente/#/determine" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.DETERMINE,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "auslfe",
    }as Albo,
    "https://trasparenza.ausl.fe.it/albi/profilo-committente/#/atti-di-esecuzione" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.ATTI_DI_ESECUZIONE,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "auslfe",
    }as Albo,
    "https://trasparenza.ausl.fe.it/albi/registro-accessi" :  {
        tipo: TipiTabellaAlbi.REGISTRO_ACCESSI,
        specifica: null,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "auslfe",
    }as Albo,

    //AOSPFE PROD URL
    "https://albonline.ospfe.it/albi" : {
        tipo: TipiTabellaAlbi.ALBO,
        specifica:  null,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "aospfe",
    }as Albo,
    "https://trasparenza-atti.ospfe.it/albi/index_dirigenti.html" : {
        tipo: TipiTabellaAlbi.PROVVEDIMENTI,
        specifica: SpecificheTabellaAlbi.DIRIGENTI,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "aospfe",
    }as Albo,
    "https://trasparenza-atti.ospfe.it/albi/index_politici.html" : {
        tipo: TipiTabellaAlbi.PROVVEDIMENTI,
        specifica: SpecificheTabellaAlbi.POLITICI,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "aospfe",
    }as Albo,
    "https://trasparenza-atti.ospfe.it/albi/profilo-committente/#/atti" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.ATTI,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "aospfe",
    }as Albo,
    "https://trasparenza-atti.ospfe.it/albi/profilo-committente/#/procedure" : {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.PROCEDURE,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "aospfe",
    }as Albo,
    "https://trasparenza-atti.ospfe.it/albi/profilo-committente/#/esiti" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.ESITI,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "aospfe",
    }as Albo,
    "https://trasparenza-atti.ospfe.it/albi/profilo-committente/#/determine" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.DETERMINE,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "aospfe",
    }as Albo,
    "https://trasparenza-atti.ospfe.it/albi/profilo-committente/#/atti-di-esecuzione" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.ATTI_DI_ESECUZIONE,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "aospfe",
    }as Albo,
    "https://trasparenza-atti.ospfe.it/albi/registro-accessi" :  {
        tipo: TipiTabellaAlbi.REGISTRO_ACCESSI,
        specifica: null,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "aospfe",
    }as Albo,

    //AUSLPR PROD URL
    "https://albonline.ausl.pr.it/albi" : {
        tipo: TipiTabellaAlbi.ALBO,
        specifica:  null,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "auslpr",
    }as Albo,
    "https://trasparenza.ausl.pr.it/albi/index_dirigenti.html" : {
        tipo: TipiTabellaAlbi.PROVVEDIMENTI,
        specifica: SpecificheTabellaAlbi.DIRIGENTI,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "auslpr",
    }as Albo,
    "https://trasparenza.ausl.pr.it/albi/index_politici.html" : {
        tipo: TipiTabellaAlbi.PROVVEDIMENTI,
        specifica: SpecificheTabellaAlbi.POLITICI,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "auslpr",
    }as Albo,
    "https://trasparenza.ausl.pr.it/albi/profilo-committente/#/atti" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.ATTI,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "auslpr",
    }as Albo,
    "https://trasparenza.ausl.pr.it/albi/profilo-committente/#/procedure" : {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.PROCEDURE,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "auslpr",
    }as Albo,
    "https://trasparenza.ausl.pr.it/albi/profilo-committente/#/esiti" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.ESITI,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "auslpr",
    }as Albo,
    "https://trasparenza.ausl.pr.it/albi/profilo-committente/#/determine" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.DETERMINE,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "auslpr",
    }as Albo,
    "https://trasparenza.ausl.pr.it/albi/profilo-committente/#/atti-di-esecuzione" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.ATTI_DI_ESECUZIONE,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "auslpr",
    }as Albo,
    "https://trasparenza.ausl.pr.it/albi/registro-accessi" :  {
        tipo: TipiTabellaAlbi.REGISTRO_ACCESSI,
        specifica: null,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "auslpr",
    }as Albo,
    
        
    //AOSPPR PROD URL
    "https://albonline.ao.pr.it/albi" : {
        tipo: TipiTabellaAlbi.ALBO,
        specifica:  null,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "aosppr",
    }as Albo,
    "https://trasparenza.ao.pr.it/albi/index_dirigenti.html" : {
        tipo: TipiTabellaAlbi.PROVVEDIMENTI,
        specifica: SpecificheTabellaAlbi.DIRIGENTI,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "aosppr",
    }as Albo,
    "https://trasparenza.ao.pr.it/albi/index_politici.html" : {
        tipo: TipiTabellaAlbi.PROVVEDIMENTI,
        specifica: SpecificheTabellaAlbi.POLITICI,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "aosppr",
    }as Albo,
    "https://trasparenza.ao.pr.it/albi/profilo-committente/#/atti" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.ATTI,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "aosppr",
    }as Albo,
    "https://trasparenza.ao.pr.it/albi/profilo-committente/#/procedure" : {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.PROCEDURE,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "aosppr",
    }as Albo,
    "https://trasparenza.ao.pr.it/albi/profilo-committente/#/esiti" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.ESITI,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "aosppr",
    }as Albo,
    "https://trasparenza.ao.pr.it/albi/profilo-committente/#/determine" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.DETERMINE,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "aosppr",
    }as Albo,
    "https://trasparenza.ao.pr.it/albi/profilo-committente/#/atti-di-esecuzione" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.ATTI_DI_ESECUZIONE,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "aosppr",
    }as Albo,
    "https://trasparenza.ao.pr.it/albi/registro-accessi" :  {
        tipo: TipiTabellaAlbi.REGISTRO_ACCESSI,
        specifica: null,
        ambiente: AmbientiTabellaAlbi.PROD,
        azienda: "aosppr",
    }as Albo,
 
    //AZERO PROD URL
    "https://albi.azero.veneto.it/albi/albo" : {
        tipo: TipiTabellaAlbi.ALBO,
        specifica:  null,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "azero",
    }as Albo,
    "https://albi.azero.veneto.it/albi/albo/index_dirigenti.html" : {
        tipo: TipiTabellaAlbi.PROVVEDIMENTI,
        specifica: SpecificheTabellaAlbi.DIRIGENTI,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "azero",
    }as Albo,
    "https://albi.azero.veneto.it/albi/albo/index_politici.html" : {
        tipo: TipiTabellaAlbi.PROVVEDIMENTI,
        specifica: SpecificheTabellaAlbi.POLITICI,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "azero",
    }as Albo,
    "https://albi.azero.veneto.it/albi/profilo-committente/#/atti" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.ATTI,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "azero",
    }as Albo,
    "https://albi.azero.veneto.it/albi/profilo-committente/#/procedure" : {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.PROCEDURE,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "azero",
    }as Albo,
    "https://albi.azero.veneto.it/albi/profilo-committente/#/esiti" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.ESITI,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "azero",
    }as Albo,
    "https://albi.azero.veneto.it/albi/profilo-committente/#/determine" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.DETERMINE,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "azero",
    }as Albo,
    "https://albi.azero.veneto.it/albi/profilo-committente/#/atti-di-esecuzione" :  {
        tipo: TipiTabellaAlbi.PROFILO_COMMITTENTE,
        specifica:  SpecificheTabellaAlbi.ATTI_DI_ESECUZIONE,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "azero",
    }as Albo,
    "https://albi.azero.veneto.it/albi/registro-accessi" :  {
        tipo: TipiTabellaAlbi.REGISTRO_ACCESSI,
        specifica: null,
        ambiente: AmbientiTabellaAlbi.TEST,
        azienda: "azero",
    }as Albo
}