import { Pubblicazione, TipologiaAlbo } from "src/app/entities/Pubblicazione";
import { Relazione } from "src/app/entities/TipoDocumento";

export class ExtendedPubblicazione extends Pubblicazione {
  private _datiPubblicazioneVisualizzazione: string;
  private _datiRegistroVisualizzazione: string;
  private _esecutivitaVisualizzazione: string;
  private _registroVisualizzazione: string;
  private _dataEsecutivitaVisualizzazione: Date;
  private _dataFinePubblicazioneVisualizzazione: Date;
  private _dataInizioPubblicazioneVisualizzazione: Date;
  private _dataAdozioneVisualizzazione: Date;
  private _dataRegistrazioneVisualizzazione: Date;
  private _numeroRegistroVisualizzazione: string;
  private _annoRegistroVisualizzazione: string;
  private _attoDiIniziativaVisualizzazione: string;
  private _provvedimentoFinaleVisualizzazione: string;
  private _dataChiusuraVisualizzazione: Date;
  private _dataIniziativaVisualizzazione: Date;


  constructor() { super(); }

  public get datiPubblicazioneVisualizzazione(): string {
    return this._datiPubblicazioneVisualizzazione;
  }

  public set datiPubblicazioneVisualizzazione(datiPubblicazioneVisualizzazione: string) {
    this._datiPubblicazioneVisualizzazione = this.numeroPubblicazione + "/" + this.annoPubblicazione;
  }

  public get datiRegistroVisualizzazione(): string {
    return this._datiRegistroVisualizzazione;
  }

  public set datiRegistroVisualizzazione(datiRegistroVisualizzazione: string) {
    if (this.tipiDocumentiList && this.tipiDocumentiList[0]) {
      const pad: string = "0000000";
      this._datiRegistroVisualizzazione =
        this.tipiDocumentiList[0].registro + " " +
        pad.substring(0, pad.length - this.tipiDocumentiList[0].numeroRegistro.toString().length) + this.tipiDocumentiList[0].numeroRegistro +
        "/" + this.tipiDocumentiList[0].annoRegistro;
    }
  }

  public get esecutivitaVisualizzazione(): string {
    return this._esecutivitaVisualizzazione;
  }

  public set esecutivitaVisualizzazione(esecutivitaVisualizzazione: string) {
    this._esecutivitaVisualizzazione = "";
    if (this.tipiDocumentiList && this.tipiDocumentiList[0] && this.tipiDocumentiList[0].esecutivita) {
      this._esecutivitaVisualizzazione = this.tipiDocumentiList[0].esecutivita.toString();
    }
  }

  public get dataEsecutivitaVisualizzazione(): Date {
    return this._dataEsecutivitaVisualizzazione;
  }

  public set dataEsecutivitaVisualizzazione(dataEsecutivitaVisualizzazione: Date) {
    this._dataEsecutivitaVisualizzazione = null;
    if (this.tipiDocumentiList && this.tipiDocumentiList[0]) {
      this._dataEsecutivitaVisualizzazione = this.tipiDocumentiList[0].dataEsecutivita;
    }
  }

  public get dataInizioPubblicazioneVisualizzazione(): Date {
    return this._dataInizioPubblicazioneVisualizzazione;
  }

  public set dataInizioPubblicazioneVisualizzazione(dataInizioPubblicazioneVisualizzazione: Date) {
    this._dataInizioPubblicazioneVisualizzazione = null;
    if (this.periodoPubblicazioneList && this.periodoPubblicazioneList.length != 0) {
      this._dataInizioPubblicazioneVisualizzazione = this.periodoPubblicazioneList[0].dataDal;
    }
  }

  public get dataFinePubblicazioneVisualizzazione(): Date {
    return this._dataFinePubblicazioneVisualizzazione;
  }

  public set dataFinePubblicazioneVisualizzazione(dataFinePubblicazioneVisualizzazione: Date) {
    this._dataFinePubblicazioneVisualizzazione = null;
    if (this.periodoPubblicazioneList && this.periodoPubblicazioneList.length != 0) {
      this._dataFinePubblicazioneVisualizzazione = this.periodoPubblicazioneList[0].dataAl;
    }
  }

  public get dataAdozioneVisualizzazione(): Date {
    return this._dataAdozioneVisualizzazione;
  }

  public set dataAdozioneVisualizzazione(dataAdozioneVisualizzazione: Date) {
    this._dataAdozioneVisualizzazione = null;
    if (this.tipiDocumentiList && this.tipiDocumentiList[0]) {
      this._dataAdozioneVisualizzazione = this.tipiDocumentiList[0].dataAdozione;
    }
  }

  public get registroVisualizzazione(): string {
    return this._registroVisualizzazione;
  }

  public set registroVisualizzazione(registroVisualizzazione: string) {
    this._registroVisualizzazione = "";
    if (this.tipiDocumentiList && this.tipiDocumentiList[0]) {
      this._registroVisualizzazione = this.tipiDocumentiList[0].registro.toString();
    }
  }

  public get numeroRegistroVisualizzazione(): string {
    return this._numeroRegistroVisualizzazione;
  }

  public set numeroRegistroVisualizzazione(numeroRegistroVisualizzazione: string) {
    if (this.tipiDocumentiList && this.tipiDocumentiList[0]) {
      const pad: string = "0000000";
      this._numeroRegistroVisualizzazione = pad.substring(0, pad.length - this.tipiDocumentiList[0].numeroRegistro.toString().length) + this.tipiDocumentiList[0].numeroRegistro;
    }
  }

  public get annoRegistroVisualizzazione(): string {
    return this._annoRegistroVisualizzazione;
  }

  public set annoRegistroVisualizzazione(annoRegistroVisualizzazione: string) {
    this._annoRegistroVisualizzazione = "";
    if (this.tipiDocumentiList && this.tipiDocumentiList[0]) {
      this._annoRegistroVisualizzazione = this.tipiDocumentiList[0].annoRegistro.toString();
    }
  }

  public get dataRegistrazioneVisualizzazione(): Date {
    return this._dataRegistrazioneVisualizzazione;
  }

  public set dataRegistrazioneVisualizzazione(dataRegistrazioneVisualizzazione: Date) {
    this._dataRegistrazioneVisualizzazione = null;
    if (this.tipiDocumentiList && this.tipiDocumentiList[0]) {
      this._dataRegistrazioneVisualizzazione = this.tipiDocumentiList[0].dataRegistro;
    }
  }

  public get attoDiIniziativaVisualizzazione(): string {
    return this._attoDiIniziativaVisualizzazione;
  }

  public set attoDiIniziativaVisualizzazione(attoDiIniziativaVisualizzazione: string) {
    this._attoDiIniziativaVisualizzazione = null;
    if (this.tipiDocumentiList && this.tipologiaAlbo.toString() == TipologiaAlbo.REGISTRO_ACCESSI) {
      const tipiDocumentiListIniziale = this.tipiDocumentiList.find(y => y.tipoRelazione.toString() == Relazione[Relazione.INIZIALE]);
      if (tipiDocumentiListIniziale) {
        const pad: string = "0000000";
        this._attoDiIniziativaVisualizzazione = tipiDocumentiListIniziale.registro + " " +
          pad.substring(0, pad.length - tipiDocumentiListIniziale.numeroRegistro.toString().length) +
          tipiDocumentiListIniziale.numeroRegistro + "/" + tipiDocumentiListIniziale.annoRegistro;
      }
    }
  }
  public get provvedimentoFinaleVisualizzazione(): string {
    return this._provvedimentoFinaleVisualizzazione;
  }

  public set provvedimentoFinaleVisualizzazione(provvedimentoFinaleVisualizzazione: string) {
    this._provvedimentoFinaleVisualizzazione = null;
    if (this.tipiDocumentiList && this.tipologiaAlbo.toString() == TipologiaAlbo.REGISTRO_ACCESSI) {
      const tipiDocumentiListFinale = this.tipiDocumentiList.find(y => y.tipoRelazione.toString() == Relazione[Relazione.FINALE]);
      if (tipiDocumentiListFinale) {
        const pad: string = "0000000";
        this._provvedimentoFinaleVisualizzazione = tipiDocumentiListFinale.registro + " " +
          pad.substring(0, pad.length - tipiDocumentiListFinale.numeroRegistro.toString().length) +
          tipiDocumentiListFinale.numeroRegistro + "/" + tipiDocumentiListFinale.annoRegistro;
      }
    }
  }

  public get dataIniziativaVisualizzazione(): Date {
    return this._dataIniziativaVisualizzazione;
  }

  public set dataIniziativaVisualizzazione(dataIniziativaVisualizzazione: Date) {
    this._dataIniziativaVisualizzazione = null;
    if (this.tipiDocumentiList && this.tipologiaAlbo.toString() == TipologiaAlbo[TipologiaAlbo.REGISTRO_ACCESSI]) {
      this._dataIniziativaVisualizzazione = this.tipiDocumentiList.find(y => y.tipoRelazione.toString() == Relazione[Relazione.INIZIALE])?.dataRegistro;
    }
  }

  public get dataChiusuraVisualizzazione(): Date {
    return this._dataChiusuraVisualizzazione;
  }

  public set dataChiusuraVisualizzazione(dataChiusuraVisualizzazione: Date) {
    this._dataChiusuraVisualizzazione = null;
    if (this.tipiDocumentiList && this.tipologiaAlbo.toString() == TipologiaAlbo[TipologiaAlbo.REGISTRO_ACCESSI]) {
      this._dataChiusuraVisualizzazione = this.tipiDocumentiList.find(y => y.tipoRelazione.toString() == Relazione[Relazione.FINALE])?.dataRegistro;
    }
  }

}