import { Injectable } from '@angular/core';
import { NextSDREntityProvider } from '@bds/next-sdr';
import { HttpClient } from '@angular/common/http';
import { ENTITIES_STRUCTURE, getInternautaUrl } from '../albi-constants';
import { DatePipe } from '@angular/common';
import { Allegato } from '../entities/Allegato';

@Injectable({
  providedIn: 'root'
})
export class AllegatoService extends NextSDREntityProvider{
 
  constructor(protected _http: HttpClient, protected _datepipe: DatePipe) {
    super(_http, _datepipe, ENTITIES_STRUCTURE.albi.allegato, getInternautaUrl());
  }
 
  downloadAllegatoFile(allegato: Allegato) {
    const url = getInternautaUrl() + "/" + ENTITIES_STRUCTURE.albi.allegato.path + "/" +  allegato.id + "/download";
    return this.http.get(url, {responseType:"blob"});
  }
}