import { Injectable } from '@angular/core';
import { AdditionalDataDefinition, FiltersAndSorts, NextSDREntityProvider } from '@bds/next-sdr';
import { HttpClient } from '@angular/common/http';
import { Albo, ENTITIES_STRUCTURE, getInternautaUrl, TipiTabellaAlbi } from '../albi-constants';
import { DatePipe } from '@angular/common';
import { Parameters } from '../utilities/parameters';
import { BehaviorSubject, Observable } from 'rxjs';
import { ParametroAziende } from '../entities/ParametroAziende';

@Injectable({
  providedIn: 'root'
})
export class ParametersLoadService extends NextSDREntityProvider{
 
  private parameters$: BehaviorSubject<Parameters> = new BehaviorSubject<Parameters>(null);

  constructor(protected _http: HttpClient, protected _datepipe: DatePipe) {
    super(_http, _datepipe, ENTITIES_STRUCTURE.albi.parametroaziende, getInternautaUrl());
  } 
  
  public loadParameters(alboSelected: Albo,){
    const filterAndSort = new FiltersAndSorts();
    filterAndSort.addAdditionalData(new AdditionalDataDefinition("tipoTabella", alboSelected.tipo));
    filterAndSort.addAdditionalData(new AdditionalDataDefinition("azienda", alboSelected.azienda));
    const parameters: Parameters={} as Parameters;
    this.getData(null,filterAndSort,null,null).subscribe((res: any) =>{
      //console.log(res);
      res.results.forEach(parametroAziende =>{
        parameters[parametroAziende.nome]=parametroAziende.valore
      });
      this.parameters$.next(parameters);
    })      
  }

  public get parametersEvent(): Observable<Parameters> {
    return this.parameters$.asObservable();
  }
  
}