import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { albiMainFieldsCols, codiceEsitiFields, cols, colsCSV, esecutivitaFields, esitoFields, profiloCommittenteFieldsCols, provvedimentiFieldsCols, registroAccessiFieldsCols, registroFields, registroFields2 } from './pubblicazioni-list-constants';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { AdditionalDataDefinition, FilterDefinition, FiltersAndSorts, FILTER_TYPES, SortDefinition, SORT_MODES, PagingConf } from "@bds/next-sdr";
import { ColonnaBds, CsvExtractor } from "@bds/common-tools";
import { Subscription } from 'rxjs';
import { buildLazyEventFiltersAndSorts } from "@bds/primeng-plugin";
import { Albo, SpecificheTabellaAlbi, TipiTabellaAlbi } from '../../albi-constants';
import { ExtendedPubblicazione } from './extended-pubblicazione';
import { PubblicazioniListService } from './pubblicazioni-list.service';
import { ViewChild } from '@angular/core';
import { ColumnFilter, Table } from 'primeng/table';
import { Pubblicazione, TipologiaAlbo } from 'src/app/entities/Pubblicazione'; 
import { ViewChildren } from '@angular/core';
import { QueryList } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Calendar } from 'primeng/calendar'; 
import { AutoComplete } from 'primeng/autocomplete'; 
import { PubblicazioniAlboAttiveListService } from './pubblicazioniAlboAttive-list.service';
@Component({
  selector: 'app-pubblicazioni-list',
  templateUrl: './pubblicazioni-list.component.html',
  styleUrls: ['./pubblicazioni-list.component.scss']
})
export class PubblicazioniListComponent implements OnInit {

  public pubblicazioni: ExtendedPubblicazione[]; 
  public cols: ColonnaBds[]; 
  public initialSortField: string;
  public rowsNumber: number = 20;
  public totalRecords: number;
  private resetPubblicazioniArrayLenght: boolean = true;
  public loading: boolean = false;
  private loadPubblicazioniListSubscription: Subscription;
  public selectedColumns: ColonnaBds[];
  public dataMinima: Date = new Date("2000-01-01");
  public dataMassima: Date = new Date("2030-12-31");
  public filtriPuliti: boolean = true;
  public projectionToUse: string;
  public selectedPubblicazione: Pubblicazione;
  public registroFiltrabili: ValueAndNomeObj[]= registroFields;
  public selectedRegistroFiltrabili: ValueAndNomeObj= registroFields[0];
  public registroFiltrabili2: any = registroFields2;
  public selectedRegistroFiltrabili2: any = { value: null, nome: ""};
  public esitoFiltrabili: ValueAndNomeObj[]= esitoFields;    
  public esecutivitaFiltrabili: ValueAndNomeObj[]= esecutivitaFields;    
  public selectCodiceEsitiItems = codiceEsitiFields;
  public selectedCodiceEsiti= this.selectCodiceEsitiItems[0];
  public exportCsvInProgress: boolean = false;
  public showEsitiCaption: boolean = false;
  public exportCsvName: string;
 

  

  private storedLazyLoadEvent: LazyLoadEvent;
  private pageConf: PagingConf = { mode: "LIMIT_OFFSET_NO_COUNT", conf: { limit: 0, offset: 0 } };
 

  @Output() showRightPanel = new EventEmitter<{showPanel: boolean, rowSelected: Pubblicazione }>();
  @Output() showSpinning = new EventEmitter<{showSpinning: boolean}>();
 
  //@Input() azienda: string;
  @Input() albo: Albo;

  
  @ViewChildren(ColumnFilter) filterColumns: QueryList<ColumnFilter>;       
  @ViewChild("dt") public dataTable: Table; 
  @ViewChild("autocompleteEsecutivita") public autocompleteEsecutivita: AutoComplete;

  constructor(private pubblicazioniListService: PubblicazioniListService,
    private pubblicazioniAlboAttiveListService: PubblicazioniAlboAttiveListService,
    private messageService: MessageService,
    private datepipe: DatePipe
  ) { } 
  ngOnInit(): void { 
    this.loadConfigurationAndSetItUp();
  }


  //When selecting a row pubb.detail component is called with row params
  public onRowSelected(show: boolean, rowContent: Pubblicazione){
    this.showRightPanel.emit({
      showPanel: show,
      rowSelected: rowContent
    })
  }
 
  /**
   * Metodo chiamato dalla tabella.
   * Calcola il pageconf, salva i filtri e chiama la loadData
   * @param event
   */
  public onLazyLoad(event: LazyLoadEvent): void {
    // console.log("event lazyyload", event);

    if (event.first === 0 && event.rows === this.rowsNumber) {
      event.rows = event.rows * 2;
      //this.resetPubblicazioniArrayLenght = true;
    }
    //console.log(`Chiedo ${this.pageConf.conf.limit} righe con offset di ${this.pageConf.conf.offset}`);
    this.storedLazyLoadEvent = event;
    this.loadData();
  }

  /**
   * Builda i filtri della tabella. Aggiunge eventuali altri filtri.
   * Carica i docs per la lista.
   * @param event
   */
  private loadData(): void {
    // console.log(this.storedLazyLoadEvent)    
    this.loading = true;
    this.pageConf.conf = {
      limit: this.storedLazyLoadEvent.rows,
      offset: this.storedLazyLoadEvent.first
    };
    if (this.loadPubblicazioniListSubscription) {
      this.loadPubblicazioniListSubscription.unsubscribe();
      this.loadPubblicazioniListSubscription = null;
    }
    const filtersAndSorts: FiltersAndSorts = this.buildCustomFilterAndSort();
    if (this.albo.tipo === TipiTabellaAlbi.ALBO) {
      this.loadPubblicazioniListSubscription = this.pubblicazioniAlboAttiveListService.getData(
        this.projectionToUse,
        filtersAndSorts,
        buildLazyEventFiltersAndSorts(this.storedLazyLoadEvent, this.cols, this.datepipe),
        this.pageConf).subscribe({
          next:(data: any) => {
            this.loadDataAfterSubscribe(data);
          },
          error: ()=> {
            this.messageService.add({
              severity: "warn",
              key: "pubblicazioniListToast",
              summary: "Attenzione",
              detail: `Si è verificato un errore nel caricamento. Riprovare più tardi.`
            });
          }
        });
    } else {
      this.loadPubblicazioniListSubscription = this.pubblicazioniListService.getData(
        this.projectionToUse,
        filtersAndSorts,
        buildLazyEventFiltersAndSorts(this.storedLazyLoadEvent, this.cols, this.datepipe),
        this.pageConf).subscribe({
          next:(data: any) => {
            this.loadDataAfterSubscribe(data);
          },
          error: ()=> {
            this.messageService.add({
              severity: "warn",
              key: "pubblicazioniListToast",
              summary: "Attenzione",
              detail: `Si è verificato un errore nel caricamento. Riprovare più tardi.`
            });
          }
        });
      }
  }

  private loadDataAfterSubscribe(data: any){
    
    //console.log(data);
    this.totalRecords = data.page.totalElements;
    this.loading = false;
    let results = data.results;
    
    if (this.resetPubblicazioniArrayLenght) {
      /* Ho bisogno di far capire alla tabella quanto l'array  è virtualmente grande
      in questo modo la scrollbar sarà sufficientemente lunga per scrollare fino all'ultimo elemento
      ps:a quanto pare la proprietà totalRecords non è sufficiente. */
      this.resetPubblicazioniArrayLenght = false;
      this.dataTable.resetScrollTop();
      this.pubblicazioni = Array.from({ length: this.totalRecords });
    }
    if (this.albo.tipo === TipiTabellaAlbi.ALBO){
      results = this.convertPubblicazioneAlboAttivaIntoPubblicazione(results);
    }
    if (this.pageConf.conf.offset === 0 && data.page.totalElements < this.pageConf.conf.limit) {
      /* Questo meccanismo serve per cancellare i risultati di troppo della tranche precedente.
      Se entro qui probabilmente ho fatto una ricerca */
      Array.prototype.splice.apply(this.pubblicazioni, [0, this.pubblicazioni.length, ...this.setCustomProperties(results)]);
    } else {
      Array.prototype.splice.apply(this.pubblicazioni, [this.storedLazyLoadEvent.first, this.storedLazyLoadEvent.rows, ...this.setCustomProperties(results)]);
    }
    this.pubblicazioni = [...this.pubblicazioni]; // trigger change detection
    //console.log(this.pubblicazioni)
  }

  /**
   * Converto da PubblicazioneAlboAttiva a Pubblicazione
   */
  private convertPubblicazioneAlboAttivaIntoPubblicazione(pubblicazioniAlboAttivaList: any[]): Pubblicazione[]{
    const pubList: Pubblicazione[] = [];
    pubblicazioniAlboAttivaList.forEach(pubAA => {
      const pub: Pubblicazione = new Pubblicazione;
      pub.allegatiList = pubAA.allegatiList;
      pub.annoPubblicazione = pubAA.annoPubblicazione;
      pub.articolazione = pubAA.articolazione;
      pub.dataInserimento = pubAA.dataInserimento;
      pub.dataUltimaModifica = pubAA.dataUltimaModifica;
      pub.id = pubAA.id;
      pub.idApplicazione = pubAA.fk_idApplicazione;
      pub.idAzienda = pubAA.fk_idAzienda;
      pub.idProvvedimento = null;
      pub.idRegistroAccessi = null;
      pub.numeroPubblicazione = pubAA.numeroPubblicazione;
      pub.oggetto = pubAA.oggetto;
      pub.periodoPubblicazioneList = pubAA.periodoPubblicazioneList;
      pub.pubblicazioneCommittente = null;
      pub.sottoTipologiaAlbo = null;
      pub.tipiDocumentiList = pubAA.tipiDocumentiList;
      pub.tipologiaAlbo = TipologiaAlbo.ALBO;
      pub.version = pubAA.version;
      pubList.push(pub);
    });
    return pubList;
  }
  

  /**
     * Parso Pubblicazione[] facendolo diventare ExtendedPubblicazione[] con le
     * proprietà utili alla visualizzazione popolate
     * @param docsList
     * @returns
     */
  private setCustomProperties(pubblicazioniList: Pubblicazione[]): ExtendedPubblicazione[] {
    const extendedPubblicazione: ExtendedPubblicazione[] = pubblicazioniList as ExtendedPubblicazione[];
    extendedPubblicazione.forEach((pubblicazione: ExtendedPubblicazione) => {
      Object.setPrototypeOf(pubblicazione, ExtendedPubblicazione.prototype);
      pubblicazione.datiPubblicazioneVisualizzazione = null;
      pubblicazione.datiRegistroVisualizzazione = null;
      pubblicazione.esecutivitaVisualizzazione = null;
      pubblicazione.dataEsecutivitaVisualizzazione = null;
      pubblicazione.dataFinePubblicazioneVisualizzazione = null;
      pubblicazione.dataInizioPubblicazioneVisualizzazione = null;
      pubblicazione.dataAdozioneVisualizzazione = null;
      pubblicazione.dataRegistrazioneVisualizzazione=null;
      pubblicazione.registroVisualizzazione=null;
      pubblicazione.annoRegistroVisualizzazione=null;
      pubblicazione.numeroRegistroVisualizzazione=null;   
      pubblicazione.attoDiIniziativaVisualizzazione=null;
      pubblicazione.dataIniziativaVisualizzazione=null;
      pubblicazione.dataChiusuraVisualizzazione=null;
      pubblicazione.provvedimentoFinaleVisualizzazione=null;

    });
    return extendedPubblicazione;
  }


  /**
* Metodo che intercetta la ricerca globale. E' solo un passa carte.
* Di fatto poi scatta l'onLazyLoad
* @param event
* @param matchMode
*/
  public applyFilterGlobal(event: Event, matchMode: string) {
    const stringa: string = (event.target as HTMLInputElement).value;
    if (!!!stringa || stringa === "") {
      this.resetSort();
    }
    this.dataTable.filterGlobal(stringa, matchMode);
  }


  /**
 * Metodo che reimposta il sort di default. Cioè al campo definito in initialSortField
 */
  public resetSort(): void {
    this.dataTable.sortField = this.initialSortField;
    this.dataTable.sortOrder = this.dataTable.defaultSortOrder;
    this.dataTable.sortSingle();
  }

  /**
   * Metodo che toglie il sort dalle colonne della tabella.
   * In particolare è usata dagli input dei campi che quando usati ordinano per ranking
   */
  public removeSort(): void {
    this.dataTable.sortField = null;
    this.dataTable.sortOrder = null;
    this.dataTable.tableService.onSort(null);
  }

  /**
 * Questa funzione gestisce l'utilizzo dei pulsanti custom dei calendari
 * @param calendar 
 * @param command 
 * @param event 
 */
  public handleCalendarButtonEvent(calendar: Calendar, command: string, event: Event, filterCallback: (value: Date[]) => {}) {
    if (command === "doFilter" || command === "onClickOutside") { // pulsante OK
      calendar.hideOverlay();
    } else if (command === "setToday") { // pulsante OGGI
      calendar.writeValue([new Date(), null]);
      calendar.hideOverlay();
    } else if (command === "clear") { // pulsante SVUOTA
      calendar.onClearButtonClick(event);
    }
    if (calendar.inputId === "calendar") {
      calendar.clearButtonStyleClass;
    }
    filterCallback(calendar.value);
  }

 
  /**
 * Funzione che si occupa di fare il clear di tutti i filtri della tabella.
 * In particolare quelli delle autocomplete che sono "separati" dal semplice
 * table.reset() vengono fatti a patto che quell'autocomplete esista.
 * @param table
 */
    public clear(): void {
    //this.inputGobalFilter.nativeElement.value = "";
    if (this.autocompleteEsecutivita) this.autocompleteEsecutivita.writeValue(null);

    this.myDatatableReset();
  }

    /**
 * this.dataTable.reset(); fa un po' troppa roba e non riesco a gestirlo.
 * Quindi il reset me lo faccio io a mano.
 */
  public myDatatableReset() {
    for (const key in this.dataTable.filters) {
      (this.dataTable.filters as any)[key]["value"] = null;
    }
    this.dataTable.filteredValue = null;
    this.dataTable.first = 0;
    this.resetSort();
  }

  /**
  * Questa funzione si occupa di caricare la configurazione delle colonne
  * in base al tipo di albo richiesto tramite url
  * Sulla base di questo vengono poi settate le colonne visualizzate
  *
  */
  private loadConfigurationAndSetItUp(): void {
    this.nomeOfCsvFile();
    var protoCols = cols;
    let colsToUse;  
    switch (this.albo.tipo) {
      case TipiTabellaAlbi.ALBO:
        colsToUse = albiMainFieldsCols;
        this.initialSortField = "dataDal";
        this.projectionToUse = "PubblicazioneAlboAttivaWithAllegatiListAndPeriodoPubblicazioneListAndTipiDocumentiList";

        protoCols = cols.map(x => {
          // con questo map tolgo il la parte periodoPubblicazioneList da filtro e ordinamento 
          // cosi che la riceca possa essere eseguita correttamente su pubblicazioniAlboAttive
          const stringToReplace = "periodoPubblicazioneList.";
          if (x.filterField.startsWith(stringToReplace)) {
            x.filterField = x.filterField.replace(stringToReplace,"")
          }
          if (typeof(x.sortField) === "string") {
            if (x.sortField.startsWith(stringToReplace)) {
              x.sortField = x.sortField.replace(stringToReplace,"")
            }
          } else if (typeof(x.sortField) === "object"){
            x.sortField.forEach(x => {
              if (x.startsWith(stringToReplace)) {
                x = x.replace(stringToReplace,"")
              }
            })
          }
          return x;
        });
        break;
      case TipiTabellaAlbi.PROVVEDIMENTI:
        colsToUse = provvedimentiFieldsCols;
        this.initialSortField = "dataInserimento";
        this.projectionToUse = "PubblicazioneWithAllegatiListAndProvvedimentoAndTipiDocumentiList";
        break;
      case TipiTabellaAlbi.PROFILO_COMMITTENTE:    
        this.initialSortField = null; 
        this.projectionToUse = "PubblicazioneWithAllegatiListAndPeriodoPubblicazioneListAndPubblicazioneCommittenteAndTipiDocumentiList";
        colsToUse = profiloCommittenteFieldsCols;
        if(this.albo.specifica == SpecificheTabellaAlbi.ESITI){
          this.showEsitiCaption=true;
        }
        break;
      case TipiTabellaAlbi.REGISTRO_ACCESSI:
        colsToUse = registroAccessiFieldsCols;
        //todo change in the next RM-s
        this.initialSortField = null;
        this.projectionToUse = "PubblicazioneWithRegistroAccessiAndTipiDocumentiList";
        break;
    }

    this.cols = [];
    colsToUse.forEach(x => {
      this.cols.push(protoCols.find(y => y.field == x))
    });
    // console.log('le colonne sono ', this.cols);

  }


  /**
  * Questo metodo costruisce filtri e sorting non provenienti dalla p-table
  * In particolare si occupa di impostare i giusti filtri a seconda
  * del tab selezionato (docsListMode)
  * @returns
  */
  private buildCustomFilterAndSort(): FiltersAndSorts {
    const filterAndSort = new FiltersAndSorts();    
    if (this.albo.tipo !== TipiTabellaAlbi.ALBO) {
      filterAndSort.addFilter(new FilterDefinition("tipologiaAlbo", FILTER_TYPES.not_string.equals, this.albo.tipo));    
    }
    filterAndSort.addFilter(new FilterDefinition("idAzienda.id", FILTER_TYPES.string.equals, this.albo.azienda));
    if(this.albo.tipo == TipiTabellaAlbi.PROFILO_COMMITTENTE || this.albo.tipo == TipiTabellaAlbi.PROVVEDIMENTI){
      filterAndSort.addFilter(new FilterDefinition("sottoTipologiaAlbo", FILTER_TYPES.not_string.equals, this.albo.specifica));      
      //filterAndSort.addSort(new SortDefinition("dataAl", SORT_MODES.desc));
    }
    if(this.albo.specifica == SpecificheTabellaAlbi.ESITI){
      this.selectedCodiceEsiti.value.forEach(element => {
        filterAndSort.addFilter(new FilterDefinition("pubblicazioneCommittente.codiceProfiloCommittente", FILTER_TYPES.string.contains,element));   
      })      
    }
    // if (this.albo.tipo === TipiTabellaAlbi.ALBO) {
    //   filterAndSort.addAdditionalData(new AdditionalDataDefinition('OperationRequested', "FilterAlbo"));
    // }
    //this is only for Registro Accessi if the user since tipo Documenti is an array and for filtering are nedded 2 conditions same time 
    //this case is managed bu backend interceptor
    //todo maybe is better to create tscol in db 
    if(this.albo.tipo==TipiTabellaAlbi.REGISTRO_ACCESSI){ 
      if(this.storedLazyLoadEvent.filters.provvedimentoFinale.value){
        filterAndSort.addAdditionalData(new AdditionalDataDefinition('tipoRelazioneFinale',this.storedLazyLoadEvent.filters.provvedimentoFinale.value.toString()));
      }
      if(this.storedLazyLoadEvent.filters.attoDiIniziativa.value){
        filterAndSort.addAdditionalData(new AdditionalDataDefinition('tipoRelazioneIniziale',this.storedLazyLoadEvent.filters.attoDiIniziativa.value.toString()));
      }
      if(this.storedLazyLoadEvent.filters.dataIniziativa.value){             
        filterAndSort.addAdditionalData(new AdditionalDataDefinition('tipoRelazioneInizialeDate0',this.datepipe.transform(this.storedLazyLoadEvent.filters.dataIniziativa.value[0], "yyyy-MM-dd'T'HH:mm:ss Z")));
        if(this.storedLazyLoadEvent.filters.dataIniziativa.value[1]){
          filterAndSort.addAdditionalData(new AdditionalDataDefinition('tipoRelazioneInizialeDate1',this.datepipe.transform(this.storedLazyLoadEvent.filters.dataIniziativa.value[1], "yyyy-MM-dd'T'HH:mm:ss Z")));
        }
      }
      if(this.storedLazyLoadEvent.filters.dataChiusura.value){
        filterAndSort.addAdditionalData(new AdditionalDataDefinition('tipoRelazioneFinaleDate0',this.datepipe.transform(this.storedLazyLoadEvent.filters.dataChiusura.value[0], "yyyy-MM-dd'T'HH:mm:ss Z")));
        if(this.storedLazyLoadEvent.filters.dataChiusura.value[1]){
          filterAndSort.addAdditionalData(new AdditionalDataDefinition('tipoRelazioneFinaleDate1',this.datepipe.transform(this.storedLazyLoadEvent.filters.dataChiusura.value[1], "yyyy-MM-dd'T'HH:mm:ss Z")));
        }
      }
    }

    /**
     * Aggiungo un sort per id alla tabella, in modo che, se viene selezionata
     * una colonna con valori uguali, a ogni caricamento le righe siano sempre 
     * ordinate allo stesso modo. Unica eccezzione è quando si filtra per oggetto,
     * in questo caso il sort non viene inserito per dare priorità all'ordinamento
     * per Ranking presente in quella colonna.
     */
    if(!this.storedLazyLoadEvent.filters.oggetto.value){
      filterAndSort.addSort(new SortDefinition("id", SORT_MODES.desc));
    }

    return filterAndSort;
  }

  /**
   * Oltre desottoscrivermi dalle singole sottoscrizioni, mi
   * desottoscrivo anche dalla specifica loadDocsListSubscription
   * Che appositamente è separata in quanto viene spesso desottoscritta
   * e risottroscritta.
   */
   public ngOnDestroy(): void {

    if (this.loadPubblicazioniListSubscription) {
      this.loadPubblicazioniListSubscription.unsubscribe();
    }
  }

  //method when detail pubblicazione is closed from X
  clearSelectedRow(): void {
    this.selectedPubblicazione = null;
  }

  //method to download the csv file
  public exportCSV2() {
    this.showSpinning.emit({showSpinning: true})
    this.exportCsvInProgress = true;
    const tableTemp = {} as Table;
    Object.assign(tableTemp, this.dataTable);
    const pageConfNoLimit: PagingConf = {
      conf: {
        page: 0,
        size: 999999
      },
      mode: "PAGE_NO_COUNT"
    };
    const filtersAndSorts: FiltersAndSorts = this.buildCustomFilterAndSort();
    const serviceToUse = this.albo.tipo === TipiTabellaAlbi.ALBO ? this.pubblicazioniAlboAttiveListService : this.pubblicazioniListService;
    serviceToUse.getData(
      this.projectionToUse,
      filtersAndSorts,
      buildLazyEventFiltersAndSorts(this.storedLazyLoadEvent, this.cols, this.datepipe),
      pageConfNoLimit)
      .subscribe(
        res => {
          if (res && res.results) {
            if (this.albo.tipo === TipiTabellaAlbi.ALBO){
              res.results = this.convertPubblicazioneAlboAttivaIntoPubblicazione(res.results);
            }
            tableTemp.value = this.setCustomProperties(res.results);
            console.log(tableTemp.value) 

            tableTemp.columns = colsCSV.filter(c => this.cols.some(e => e.field === c.fieldId)); 
            console.log(tableTemp.columns)
            const extractor = new CsvExtractor();
            extractor.exportCsv(tableTemp);
          }
          this.showSpinning.emit({showSpinning: false})
          this.exportCsvInProgress = false;
        },
        err => {
          this.showSpinning.emit({showSpinning: false})
          this.exportCsvInProgress = false;
        }
      );
  }

  //method to get the name of csv doc which is downloaded
  public nomeOfCsvFile() {
    if(this.albo.specifica){ 
      this.exportCsvName=this.albo.tipo.replace(/\n/g," ") + " " +this.albo.specifica;    
    }
    else this.exportCsvName=this.albo.tipo.replace(/\n/g," "); 
  }

  //method to change bettwen different tabs of profilo-committente esiti
  public onSelectButtonItemSelection(event: any): void {
    this.selectedCodiceEsiti = event.option
    this.loadData();
  }


}


export interface ValueAndNomeObj {
  value: string;
  nome: string;
}