import { EntitiesConfiguration } from "@bds/next-sdr";
import { Azienda } from "./entities/Azienda";


export const LOCALHOST_PORT = "10010";

export enum TipiTabellaAlbi {
    ALBO = "ALBO",
    PROVVEDIMENTI = "PROVVEDIMENTI",
    PROFILO_COMMITTENTE = "PROFILO_COMMITTENTE",
    REGISTRO_ACCESSI = "REGISTRO_ACCESSI"
}

export enum SpecificheTabellaAlbi {
    DIRIGENTI = "DIRIGENTI",
    POLITICI = "POLITICI",
    ATTI = "ATTI",
    PROCEDURE = "PROCEDURE",
    ESITI = "ESITI",
    DETERMINE = "DETERMINE",
    ATTI_DI_ESECUZIONE = "ATTI_DI_ESECUZIONE"
}

export enum AmbientiTabellaAlbi {
    PROD,
    TEST,
    SVILUPPO,
    LOCALHOST
}

export interface Albo {
    tipo: TipiTabellaAlbi;
    specifica: SpecificheTabellaAlbi;
    ambiente: AmbientiTabellaAlbi;
    azienda: string;
}

export interface EntitiesStructure {
    [key: string]: EntitiesConfiguration;
}

export const ENTITIES_STRUCTURE: EntitiesStructure = {
    albi: {
        pubblicazioni: {
            path: "pubblicazione",
            standardProjections: {}
            ,
            customProjections: {
            },
            collectionResourceRel: "pubblicazione",
            keyName: "id"
        },
        pubblicazionealboattiva: {
            path: "pubblicazionealboattiva",
            standardProjections: {}
            ,
            customProjections: {
            },
            collectionResourceRel: "pubblicazionealboattiva",
            keyName: "id"
        },
        azienda: {
            path: "azienda",
            standardProjections: {}
            ,
            customProjections: {
            },
            collectionResourceRel: "azienda",
            keyName: "id"
        },
        parametroaziende: {
            path: "parametroaziende",
            standardProjections: {}
            ,
            customProjections: {
            },
            collectionResourceRel: "parametroaziende",
            keyName: "id"
        },
        allegato: {
            path: "allegato",
            standardProjections: {}
            ,
            customProjections: {
            },
            collectionResourceRel: "allegato",
            keyName: "id"
        },
    }
}

export function getInternautaUrl(): string {
    let port;
    const wl = window.location;
    if (wl.hostname === "localhost") {
        port = LOCALHOST_PORT;
    } else {
        port = wl.port;
    }
  
    const out: string = wl.protocol + "//" + wl.hostname + ":" + port + "/albi-api/resources";
  
    // console.log(out);
  
    return out;
  }
  
