import { Injectable } from '@angular/core';
import { NextSDREntityProvider } from '@bds/next-sdr';
import { HttpClient } from '@angular/common/http';
import { ENTITIES_STRUCTURE, getInternautaUrl } from '../albi-constants';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AziendaService extends NextSDREntityProvider{
 
  constructor(protected _http: HttpClient, protected _datepipe: DatePipe) {
    super(_http, _datepipe, ENTITIES_STRUCTURE.albi.azienda, getInternautaUrl());
  }
  
}