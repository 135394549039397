import { NextSDRDateInformation, NextSDRDateTypes, NextSdrEntity } from "@bds/next-sdr";
import { Pubblicazione } from "./Pubblicazione";

export class PubblicazioneCommittente implements NextSdrEntity {
    id: number;
    tipologiaGara: string;
    cig: string;
    cigAzienda: string;
    importo: string;
    fornitore: string;
    ragioniSceltaFornitore: string;
    checkFornitoreRequisitiGenerali: boolean;
    checkFornitoreRequisitiProfessionali: boolean;
    procedura: string;
    oggetto_affidamento: string;
    operatoriEconomiciInviati: string;
    operatoriEconomiciOfferenti: string;
    aggiudicatario: string;
    codiceProfiloCommittente: string;
    testoProfiloCommittente: string;
    pubblicazione: Pubblicazione;
    dataAggiudicazione: Date;
    version: string;

    nextSdrDateInformation: NextSDRDateInformation = {
        dataAggiudicazione: NextSDRDateTypes.ZonedDateTime
    }

}

export enum CodiceName {
    Soprasoglia = "Soprasoglia",
    Sottosoglia = "Sottosoglia",
    MilaEuro = "< 40 mila euro"
}


export enum CodiceProfiloCommittente {
   Atto_programmazione_deli = "atto_programmazione_deli",
   Esito_di_procedura_40000_dete = 'esito_di_procedura_40000_dete',
   Esito_di_procedura_cumulativa_40000_dete = 'esito_di_procedura_cumulativa_40000_dete',
   Esito_di_procedura_cumulativa_soprasoglia_dete = 'esito_di_procedura_cumulativa_soprasoglia_dete',
   Esito_di_procedura_cumulativa_sottosoglia_dete = 'esito_di_procedura_cumulativa_sottosoglia_dete',
   Esito_di_procedura_cumulativo = 'esito_di_procedura_cumulativo',
   Esito_di_procedura_procton = 'esito_di_procedura_procton',
   Esito_di_procedura_soprasoglia_dete = 'esito_di_procedura_soprasoglia_dete',
   Esito_di_procedura_sottosoglia_dete = 'esito_di_procedura_sottosoglia_dete',
   Nota_a_contrarre_dete = 'nota_a_contrarre_dete',
   Nota_a_contrarre_no_detail_procton = 'nota_a_contrarre_no_detail_procton',
   Nota_a_contrarre_procton = 'nota_a_contrarre_procton',
   Procedura_in_corso_dete = 'procedura_in_corso_dete',
   Procedura_in_corso_procton = 'procedura_in_corso_procton'
}