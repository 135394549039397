import { NextSdrEntity, ForeignKey, NextSDRDateTypes, NextSDRDateInformation } from "@bds/next-sdr";
import { Applicazione } from "./Applicazione";
import { Pubblicazione } from "./Pubblicazione";
import { RegistroAccessi } from "./RegistroAccessi";

export class TipoDocumento implements NextSdrEntity {
    id: number;
    registro: string;
    numeroRegistro: number;
    annoRegistro: number;
    dataRegistro: Date;
    codiceRegistro: Registro;
    idDocumento: string;
    tipologia: Documento;
    idApplicazione: Applicazione;
    esecutivita: Esecutivita;
    dataEsecutivita: Date;
    tipoRelazione: Relazione;
    dataAdozione: Date;
    idPubblicazione: Pubblicazione;
    version: string;

    nextSdrDateInformation : NextSDRDateInformation = {
        dataRegistro: NextSDRDateTypes.ZonedDateTime,
        dataEsecutivita: NextSDRDateTypes.ZonedDateTime,
        dataAdozione: NextSDRDateTypes.ZonedDateTime
    };
}


export enum Registro {
    PG = "PG",
  DETE = "DETE",
  DELI = "DELI",
  REGISTRO_ACCESSI = "REGISTRO_ACCESSI",
  
}
export enum Documento {
    PROTOCOLLO_USCITA, 
    DETERMINA, 
    DELIBERA,
    PROTOCOLLO_ENTRATA,
    ITER
}
export enum Relazione {
	SORGENTE,
	INIZIALE,
	FINALE
}

export enum Esecutivita {
	ESECUTIVA = "ESECUTIVA",
	ANNULLATA = "ANNULLATA",
	NON_ESECUTIVA = "NON_ESECUTIVA",
    IN_ATTESA = "IN_ATTESA"
};