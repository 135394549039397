import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NextSDREntityProvider } from '@bds/next-sdr';
import { ENTITIES_STRUCTURE, getInternautaUrl } from '../../albi-constants';

@Injectable({
  providedIn: 'root'
})
export class PubblicazioniAlboAttiveListService extends NextSDREntityProvider{

 
  constructor(protected _http: HttpClient, protected _datepipe: DatePipe) {
    super(_http, _datepipe, ENTITIES_STRUCTURE.albi.pubblicazionealboattiva, getInternautaUrl());
  }
  
}
