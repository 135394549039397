export const profComFields = [
    {
      field: "articolazione",
      header: "Articolazione",
      type: "string-1"
    },
    {
      field: "dataInserimento",
      header: "Data Pubblicazione",
      type: "date-1"
    },
    {
      field: "datiRegistroVisualizzazione",
      header: "Dati Registro",
      type: "string-1"
    },
    {
      field: "procedura",
      header: "Procedura/ Riferimento N°",
      type: "string"
    },
    {
      field: "tipologiaGara",
      header: "Tipologia Gara",
      type: "string"
    },
    {
      field: "cig",
      header: "CIG Accordo quadro",
      type: "string"
    },
    {
      field: "cigAzienda",
      header: "CIG Azienda",
      type: "string"
    },
    {
      field: "aggiudicatario",
      header: "Aggiudicatario",
      type: "string"
    },
    {
      field: "dataAggiudicazione",
      header: "Data Aggiudicazione",
      type: "date"
    },
    {
      field: "partitaIvaCf",
      header: "Partita IVA/Codice Fiscale",
      type: "string"
    },
    {
      field: "importo",
      header: "Importo",
      type: "string"
    },    
    {
      field: "oggettoAffidamento",
      header: "Oggetto Affidamento",
      type: "string"
    },
    {
      field: "fornitore",
      header: "Fornitore",
      type: "string"
    },
    {
      field: "operatoriEconomiciInviati",
      header: "Operatori Economici Inviati",
      type: "string"
    },
    {
      field: "operatoriEconomiciOfferenti",
      header: "Operatori Economici Offerenti",
      type: "string"
    },
    {
      field: "ragioniSceltaFornitore",
      header: "Ragioni Fornitore",
      type: "string"
    },
    {
      field: "checkFornitoreRequisitiGenerali",
      header: "Il fornitore è in possesso dei requisiti di carattere generale",
      type: "boolean"
    },
    {
      field: "checkFornitoreRequisitiProfessionali",
      header: "Il fornitore è in possesso dei requisiti tecnico professionali",
      type: "boolean"
    },
  
  ]

  export const alboFields = [
    {
      field: "_datiPubblicazioneVisualizzazione",
      header: "Pubblicazione N.",
      type: "header"
    },
    {
      field: "articolazione",
      header: "Articolazione",
      type: "string-1"
    },
    {
      field: "dataInizioPubblicazioneVisualizzazione",
      field2: "dataFinePubblicazioneVisualizzazione",
      header: "Data Pubblicazione",
      type: "date-2"
    },
    {
      field: "dataEsecutivitaVisualizzazione",
      header: "Dati Esecutività",
      type: "date-1"
    },
    {
      field: "datiRegistroVisualizzazione",
      header: "Dati Registro",
      type: "string-1"
    },
    {
      field: "dontKnow",
      header: "Dati Documento",
      type: "dontKnow"
    },
  ]
