import { NextSdrEntity, NextSDRDateInformation } from "@bds/next-sdr";
import { Pubblicazione } from "./Pubblicazione";

export class RegistroAccessi implements NextSdrEntity {
    id: number;
    tipoProcedimento: string;
    controinteressati: boolean;
    noteControinteressati: string;
    esito: EsitoIter;
    motivazione: string;
    pubblicazione: Pubblicazione;
    version: string;

    nextSdrDateInformation : NextSDRDateInformation = {
    };
}
export enum EsitoIter {
    ACCOLTO = "ACCOLTO",
    RIFIUTO_PARZIALE = "RIFIUTO_PARZIALE",
    RIFIUTO_TOTALE = "RIFIUTO_TOTALE",
    RITIRATO = "RITIRATO"
}
