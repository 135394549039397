import { Input, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Albo } from '../albi-constants';
import { AziendaService } from '../services/azienda.service';
import { MessageService } from 'primeng/api';
import { Azienda } from '../entities/Azienda';
import { Pubblicazione } from '../entities/Pubblicazione';
import { ParametersLoadService } from '../services/parameters-load.service';
import { Parameters } from '../utilities/parameters';
import { PubblicazioniListComponent } from './pubblicazioni-list/pubblicazioni-list.component';

@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss']
})
export class ContainerComponent implements OnInit {

  public azienda: Azienda;
  public logoImg: any;
  public showRightSide: boolean = false;
  public rowUnselected: boolean = false;
  public exportCsvInProgress2: boolean = false;
  public pubblicazioneElement: Pubblicazione;
  public parameters: Parameters;


  @Input('albo') albo: Albo;
  @ViewChild('list') listComponent: PubblicazioniListComponent;   

  constructor(
    private aziendaService: AziendaService, 
    private messageService: MessageService,
    private parametersLoadService: ParametersLoadService) {
    
  }

  ngOnInit(): void {
    this.aziendaService.getByIdHttpCall(this.albo.azienda).subscribe({
      next: (data: Azienda) => {
        //console.log(data);
        this.azienda = data;
        this.logoImg = this.azienda.logo;
      },
      error: (e) => {
        console.log(e);
      }
    });
    // this.azienda = this.albo.azienda;
    // console.log(this.azienda);

    this.parametersLoadService.parametersEvent.subscribe((parameters:Parameters)=>{
      this.parameters=parameters;
    }) 
  }
  
  manageRowSelected(event){
    if(this.parameters.showDetails && JSON.parse(this.parameters.showDetails)===true){
      this.showRightSide=event.showPanel;
      this.pubblicazioneElement=event.rowSelected;
      //If we close right side of spliter this will unselect the row
      if(!event.rowSelected){
        this.listComponent.clearSelectedRow();         
      }
    }  
    //console.log(this.pubblicazioneElement);
  }
  showSpinningClass(event){
    this.exportCsvInProgress2=event.showSpinning;
  }
}


/**Ora le rotte non servono più, quindi questa roba non serve */
    // private route: ActivatedRoute
  //   this.albo = {
  //     tipo: this.route.snapshot.paramMap.get('tipo') as TipiTabellaAlbi,
  //     specifica: this.route.snapshot.paramMap.get('specifica') as SpecificheTabellaAlbi || null,
  //     ambiente: null,
  //     azienda: "auslbo"
  // }

    // this.azienda = this.route.snapshot.paramMap.get('azienda');
    // this.albo.ambiente = this.route.snapshot.paramMap.get('ambiente');